const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NAME}/api/${process.env.REACT_APP_API_VERSION}` || '';
// const API_URL = `${process.env.REACT_APP_API_URL}/api/v1` || '';

// Export API_URL for use in other files
export { API_URL };

// Login user
export const loginUser = async (username, password) => {
  try {
    const response = await fetch(`${API_URL}/auth/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: username, password }),
      credentials: 'include', // Include cookies with requests
    });

    // console.log("Login Response Data:", data);
    if (!response.ok) {
      throw new Error('Login failed');
    }

    // We need to parse the response to get the data
    const data = await response.json();
    return data; // Correctly return parsed data
  } catch (error) {
    console.error('Error in loginUser function:', error);
    throw error;
  }
};

//Get user settings (theme selection)
export const fetchUserSettings = async (token) => {
  const response = await fetch(`${API_URL}/user/settings`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch user settings');
  }
  return await response.json();
};

// Update userSettings (theme selection)
export const updateUserSettings = async (token, settingsData) => {
  const response = await fetch(`${API_URL}/user/settings`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(settingsData),
  });

  if (!response.ok) {
    throw new Error('Failed to update user settings');
  }

  return await response.json(); // or return nothing if no body is expected
};

// logout user
export const logoutUser = async (token) => {
  const response = await fetch(`${API_URL}/auth/logout`, {
      method: 'POST',
      headers: {
          Authorization: `Bearer ${token}`,
      },
  });

  if (!response.ok) {
      throw new Error('Logout failed');
  }

  return await response.json();
};
// Validate token
export const validateToken = async (token) => {
  try {
    const response = await fetch(`${API_URL}/auth/validate`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Token validation failed');
    }
    return true; // Token is valid
  } catch (error) {
    console.error('Error validating token:', error);
    return false; // Token is not valid
  }
};

// Refresh access token
export const refreshAccessToken = async () => {
  try {
    const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NAME}/api/${process.env.REACT_APP_API_VERSION}` || '/vanta-tracker/api/v1';

    const response = await fetch(`${API_URL}/auth/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include', // Important to include cookies
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Refresh token error:', response.status, errorText);
      throw new Error('Refresh token failed');
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.error('Error refreshing token:', err);
    throw err;
  }
};

//Fetch user data if authenticathed
export const fetchUsers = async (token) => {
  const response = await fetch(`${API_URL}/users`,{
    headers:{
      Authorization: `Bearer ${token}`
    },
  });

  if (!response.ok){
    throw new Error('Failed to fetch users');
  }

  return await response.json();
};


// Add a new user
export const addUser = async (newUser, token) => {
  const response = await fetch(`${API_URL}/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(newUser),
  });

  if (!response.ok) {
    const errorData = await response.json();
    return Promise.reject(errorData.message);
  }

  return await response.json();
};

// Fetch IFRS classifications list
export const fetchIFRSClassifications = async (token) => {
  const response = await fetch(`${API_URL}/ifrs-classifications`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch IFRS classifications');
  }
  return await response.json();
};

// Fetch currencies list
export const fetchCurrencies = async (token) => {
  const response = await fetch(`${API_URL}/currencies`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch currencies');
  }

  return await response.json();
}

// fetch accounts list
export const fetchAccounts = async (token) => {
  const response = await fetch(`${API_URL}/accounts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch accounts');
  }

  return await response.json();
};

// Get account by ID
export const fetchAccountById = async (token, accountId) => {
  const response = await fetch(`${API_URL}/accounts/${accountId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch account details');
  }

  return await response.json();
};

// Create a new account
export const createAccount = async (token, accountData) => {
  const response = await fetch(`${API_URL}/accounts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(accountData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to create account');
  }

  return await response.json();
};

// Update an existing account
export const updateAccount = async (token, accountId, accountData) => {
  const response = await fetch(`${API_URL}/accounts/${accountId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(accountData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to update account');
  }

  return await response.json();
};

// Delete an account
export const deleteAccount = async (token, accountId) => {
  const response = await fetch(`${API_URL}/accounts/${accountId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to delete account');
  }

  return true;
};

//fetch transactions list
export const fetchTransactions = async (token) => {
  const response = await fetch(`${API_URL}/transactions/with-amount`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch transactions');
  }

  return await response.json();
}

//fetch transaction lines list
export const fetchTransactionLines = async (token) => {
  const response = await fetch(`${API_URL}/transaction-lines`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch transaction lines');
  }

  return await response.json();
};

//fetch contacts list
export const fetchContacts = async (token) => {
  const response = await fetch(`${API_URL}/contacts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch contacts');
  }

  return await response.json();
};

// Create a new contact
export const createContact = async (token, contactData) => {
  const response = await fetch(`${API_URL}/contacts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(contactData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to create contact');
  }

  return await response.json();
};

// Update an existing contact
export const updateContact = async (token, contactId, contactData) => {
  const response = await fetch(`${API_URL}/contacts/${contactId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(contactData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to update contact');
  }

  return await response.json();
};

// Delete a contact
export const deleteContact = async (token, contactId) => {
  const response = await fetch(`${API_URL}/contacts/${contactId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to delete contact');
  }

  return true;
};

// fetch tags list
export const fetchTags = async (token) => {
  const response = await fetch(`${API_URL}/tags`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch tags');
  }

  return await response.json();
};

// Create a new transaction
export const createTransaction = async (token, transactionData) => {
  const response = await fetch(`${API_URL}/transactions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(transactionData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to create transaction');
  }

  return await response.json();
};

// Update an existing transaction
export const updateTransaction = async (token, transactionId, transactionData) => {
  const response = await fetch(`${API_URL}/transactions/${transactionId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(transactionData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to update transaction');
  }

  return await response.json();
};

// Delete a transaction
export const deleteTransaction = async (token, transactionId) => {
  const response = await fetch(`${API_URL}/transactions/${transactionId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to delete transaction');
  }

  return true;
};

// Create a new IFRS classification
export const createIFRSClassification = async (token, classificationData) => {
  const response = await fetch(`${API_URL}/ifrs-classifications`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(classificationData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to create IFRS classification');
  }

  return await response.json();
};

// Update an IFRS classification
export const updateIFRSClassification = async (token, classificationId, classificationData) => {
  const response = await fetch(`${API_URL}/ifrs-classifications/${classificationId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(classificationData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to update IFRS classification');
  }

  return await response.json();
};

// Delete an IFRS classification
export const deleteIFRSClassification = async (token, classificationId) => {
  const response = await fetch(`${API_URL}/ifrs-classifications/${classificationId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to delete IFRS classification');
  }

  return true;
};

// Clone an IFRS classification
export const cloneIFRSClassification = async (token, classificationId) => {
  const response = await fetch(`${API_URL}/ifrs-classifications/${classificationId}/clone`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to clone IFRS classification');
  }

  return await response.json();
};

// Fetch budgets list
export const fetchBudgets = async (token) => {
  const response = await fetch(`${API_URL}/budgets`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch budgets');
  }

  return await response.json();
};

// Get budget by ID
export const fetchBudgetById = async (token, budgetId) => {
  const response = await fetch(`${API_URL}/budgets/${budgetId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch budget details');
  }

  return await response.json();
};

// Create a new budget
export const createBudget = async (token, budgetData) => {
  const response = await fetch(`${API_URL}/budgets`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(budgetData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to create budget');
  }

  return await response.json();
};

// Update an existing budget
export const updateBudget = async (token, budgetId, budgetData) => {
  const response = await fetch(`${API_URL}/budgets/${budgetId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(budgetData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to update budget');
  }

  return await response.json();
};

// Delete a budget
export const deleteBudget = async (token, budgetId) => {
  const response = await fetch(`${API_URL}/budgets/${budgetId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to delete budget');
  }

  return await response.json();
};

// Create a new currency
export const createCurrency = async (token, currencyData) => {
  const response = await fetch(`${API_URL}/currencies`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(currencyData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to create currency');
  }

  return await response.json();
};

// Update an existing currency
export const updateCurrency = async (token, currencyId, currencyData) => {
  const response = await fetch(`${API_URL}/currencies/${currencyId}`, {
    method: 'PATCH', // Typically PATCH for updates
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(currencyData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to update currency');
  }

  return await response.json();
};

// Delete a currency
export const deleteCurrency = async (token, currencyId) => {
  const response = await fetch(`${API_URL}/currencies/${currencyId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to delete currency');
  }

  // DELETE might return 204 No Content or a success message
  // Check status code or try to parse JSON cautiously
  if (response.status === 204) {
    return { message: 'Currency deleted successfully' };
  }
  try {
    return await response.json();
  } catch (e) {
    // Handle cases where DELETE returns no body
    return { message: 'Currency deleted successfully' };
  }
};

