import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { DataContext } from '../../contexts/DataContext';
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Alert,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { addUser } from '../../utils/api';

function AddUsers() {
  const navigate = useNavigate();
  const { accessToken } = useContext(AuthContext);
  const { users, fetchUsersData } = useContext(DataContext);
  const [user, setUser] = useState({
    name: '',
    email: '',
    role: 'us3r',
    password: '',
    lastName: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      await addUser(user, accessToken);
      setSuccess('User added successfully');
      setUser({
        name: '',
        email: '',
        role: 'us3r',
        password: '',
        lastName: ''
      });
      await fetchUsersData();
    } catch (err) {
      setError(err.message || 'Failed to add user');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ 
      p: 3,
      backgroundColor: 'var(--color-background)',
      color: 'var(--color-text)',
      minHeight: '100vh'
    }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'var(--color-text)' }}>
        Add Users
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ 
            p: 3, 
            backgroundColor: 'var(--color-surface)',
            color: 'var(--color-text)',
          }}>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Name"
                value={user.name}
                onChange={(e) => setUser({ ...user, name: e.target.value })}
                required
                sx={{ 
                  mb: 2,
                  '& .MuiInputBase-root': {
                    backgroundColor: 'var(--color-background)',
                    color: 'var(--color-text)',
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--color-text-secondary)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-text)',
                  },
                }}
              />

              <TextField
                fullWidth
                label="Last Name"
                value={user.lastName}
                onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                required
                sx={{ 
                  mb: 2,
                  '& .MuiInputBase-root': {
                    backgroundColor: 'var(--color-background)',
                    color: 'var(--color-text)',
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--color-text-secondary)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-text)',
                  },
                }}
              />

              <TextField
                fullWidth
                type="email"
                label="Email"
                value={user.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                required
                sx={{ 
                  mb: 2,
                  '& .MuiInputBase-root': {
                    backgroundColor: 'var(--color-background)',
                    color: 'var(--color-text)',
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--color-text-secondary)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-text)',
                  },
                }}
              />

              <TextField
                fullWidth
                type="password"
                label="Password"
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
                required
                sx={{ 
                  mb: 2,
                  '& .MuiInputBase-root': {
                    backgroundColor: 'var(--color-background)',
                    color: 'var(--color-text)',
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--color-text-secondary)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-text)',
                  },
                }}
              />

              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel sx={{ color: 'var(--color-text-secondary)' }}>
                  Role
                </InputLabel>
                <Select
                  value={user.role}
                  onChange={(e) => setUser({ ...user, role: e.target.value })}
                  label="Role"
                  sx={{ 
                    backgroundColor: 'var(--color-background)',
                    color: 'var(--color-text)',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--color-border)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--color-text)',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'var(--color-text)',
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: 'var(--color-surface)',
                        '& .MuiMenuItem-root': {
                          color: 'var(--color-text)',
                        },
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: 'var(--color-hover)',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="us3r">User</MenuItem>
                  <MenuItem value="supraus3r">Suprauser</MenuItem>
                  <MenuItem value="adm1n">Admin</MenuItem>
                </Select>
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
              >
                {loading ? 'Adding User...' : 'Add User'}
              </Button>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ 
            p: 3,
            backgroundColor: 'var(--color-surface)',
            color: 'var(--color-text)',
          }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'var(--color-text)' }}>
              User List
            </Typography>
            
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: 'var(--color-text)' }}>Name</TableCell>
                    <TableCell sx={{ color: 'var(--color-text)' }}>Email</TableCell>
                    <TableCell sx={{ color: 'var(--color-text)' }}>Role</TableCell>
                    <TableCell sx={{ color: 'var(--color-text)' }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.id} sx={{ 
                      '&:nth-of-type(odd)': {
                        backgroundColor: 'var(--color-background)',
                      },
                    }}>
                      <TableCell sx={{ color: 'var(--color-text)' }}>
                        {user.name} {user.lastName}
                      </TableCell>
                      <TableCell sx={{ color: 'var(--color-text)' }}>{user.email}</TableCell>
                      <TableCell sx={{ color: 'var(--color-text)' }}>{user.role}</TableCell>
                      <TableCell>
                        <IconButton 
                          size="small"
                          sx={{ color: 'var(--color-text)' }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton 
                          size="small"
                          color="error"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddUsers;
