import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import { AuthContext } from "../../contexts/AuthContext";
import { 
  Box, 
  Typography, 
  Tabs, 
  Tab, 
  TextField, 
  Button, 
  IconButton, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper
} from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { styled } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { 
  createIFRSClassification, 
  updateIFRSClassification, 
  deleteIFRSClassification, 
  cloneIFRSClassification 
} from "../../utils/api";

const CustomTreeItem = styled(TreeItem)({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  "& .MuiTreeItem-content": {
    height: "40px",
    paddingRight: "8px",
  },
  "& .MuiTreeItem-label": {
    flex: 1,
  },
  "& .actions": {
    display: "none",
  },
  "&:hover .actions": {
    display: "flex",
  },
});

function IFRSClassifications() {
  const { ifrsClassifications, fetchIFRSClassificationsData } = useContext(DataContext);
  const { accessToken } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState(0);
  const [balanceSheetItems, setBalanceSheetItems] = useState([]);
  const [incomeStatementItems, setIncomeStatementItems] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedClassification, setSelectedClassification] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    parentId: "",
  });

  useEffect(() => {
    fetchIFRSClassificationsData();
  }, [fetchIFRSClassificationsData]);

  useEffect(() => {
    if (ifrsClassifications.length > 0) {
      const balanceSheetRoots = ["Assets", "Liabilities", "Equity"];
      const incomeStatementRoots = ["Income", "Expenses"];

      setBalanceSheetItems(categorizeClassifications(balanceSheetRoots));
      setIncomeStatementItems(categorizeClassifications(incomeStatementRoots));
    }
  }, [ifrsClassifications]);

  const categorizeClassifications = (rootNames) => {
    const rootItems = ifrsClassifications.filter((item) =>
      rootNames.some((root) => item.name.startsWith(root))
    );

    return buildHierarchy(rootItems, ifrsClassifications);
  };

  const buildHierarchy = (roots, allItems) => {
    const lookup = {};
    allItems.forEach((item) => {
      lookup[item.id] = { ...item, children: [] };
    });

    allItems.forEach((item) => {
      if (item.parentId && lookup[item.parentId]) {
        lookup[item.parentId].children.push(lookup[item.id]);
      }
    });

    return roots.map((root) => lookup[root.id]).filter(Boolean);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setError(null);
    setSuccess(null);
    if (newValue === 1) {
      setFormData({
        name: "",
        description: "",
        parentId: "",
      });
    }
  };

  const handleEdit = (classification) => {
    setSelectedClassification(classification);
    setFormData({
      name: classification.name,
      description: classification.description || "",
      parentId: classification.parentId || "",
    });
    setEditDialogOpen(true);
  };

  const handleClone = async (classification) => {
    try {
      setLoading(true);
      const clonedData = await cloneIFRSClassification(accessToken, classification.id);
      await fetchIFRSClassificationsData();
      setSuccess("IFRS Classification cloned successfully");
      console.log('Cloned classification:', clonedData); // For debugging
    } catch (err) {
      console.error('Clone error:', err); // For debugging
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const openDeleteDialog = (classification) => {
    setSelectedClassification(classification);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteIFRSClassification(accessToken, selectedClassification.id);
      await fetchIFRSClassificationsData();
      setSuccess("IFRS Classification deleted successfully");
      setDeleteDialogOpen(false);
      setSelectedClassification(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (selectedClassification) {
        await updateIFRSClassification(accessToken, selectedClassification.id, formData);
        setSuccess("IFRS Classification updated successfully");
      } else {
        await createIFRSClassification(accessToken, formData);
        setSuccess("IFRS Classification created successfully");
      }
      await fetchIFRSClassificationsData();
      setFormData({ name: "", description: "", parentId: "" });
      setEditDialogOpen(false);
      setSelectedClassification(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const renderTreeItems = (nodes) =>
    nodes.map((node) => (
      <CustomTreeItem
        key={node.id}
        itemId={node.id}
        label={
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Typography sx={{ flex: 1, color: 'var(--color-text)' }}>
              {node.name} - {node.description || ""}
            </Typography>
            <Box className="actions" sx={{ display: "flex", gap: 1 }}>
              <IconButton 
                size="small" 
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(node);
                }}
                sx={{ color: 'var(--color-text)' }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton 
                size="small" 
                onClick={(e) => {
                  e.stopPropagation();
                  handleClone(node);
                }}
                sx={{ color: 'var(--color-text)' }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
              <IconButton 
                size="small" 
                onClick={(e) => {
                  e.stopPropagation();
                  openDeleteDialog(node);
                }}
                sx={{ color: 'var(--color-text)' }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        }
      >
        {node.children && renderTreeItems(node.children)}
      </CustomTreeItem>
    ));

  return (
    <Box sx={{ 
      p: 2,
      backgroundColor: 'var(--color-background)',
      color: 'var(--color-text)',
      minHeight: '100vh'
    }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'var(--color-text)' }}>
        IFRS Classifications
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      )}

      <Tabs 
        value={activeTab} 
        onChange={handleTabChange} 
        sx={{ 
          mb: 3,
          '& .MuiTab-root': {
            color: 'var(--color-text-secondary)',
          },
          '& .Mui-selected': {
            color: 'var(--color-text)',
          },
          '& .MuiTabs-indicator': {
            backgroundColor: 'var(--color-text)',
          },
        }}
      >
        <Tab label="View Classifications" />
        <Tab label="Add Classification" />
      </Tabs>

      {activeTab === 0 && (
        <Paper sx={{ 
          p: 2, 
          backgroundColor: 'var(--color-surface)',
          color: 'var(--color-text)',
        }}>
          <Typography variant="h6" sx={{ mt: 2, color: 'var(--color-text)' }}>
            Balance Sheet
          </Typography>
          <SimpleTreeView defaultExpandedItems={["balanceSheet"]}>
            {renderTreeItems(balanceSheetItems)}
          </SimpleTreeView>

          <Typography variant="h6" sx={{ mt: 4, color: 'var(--color-text)' }}>
            Income Statement
          </Typography>
          <SimpleTreeView defaultExpandedItems={["incomeStatement"]}>
            {renderTreeItems(incomeStatementItems)}
          </SimpleTreeView>
        </Paper>
      )}

      {activeTab === 1 && (
        <Paper sx={{ 
          p: 3, 
          backgroundColor: 'var(--color-surface)',
          color: 'var(--color-text)',
        }}>
          <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 600 }}>
            <TextField
              fullWidth
              label="Name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
              sx={{ 
                mb: 2,
                '& .MuiInputBase-root': {
                  backgroundColor: 'var(--color-background)',
                  color: 'var(--color-text)',
                },
                '& .MuiInputLabel-root': {
                  color: 'var(--color-text-secondary)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--color-border)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--color-text)',
                },
              }}
            />
            <TextField
              fullWidth
              label="Description"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              multiline
              rows={3}
              sx={{ 
                mb: 2,
                '& .MuiInputBase-root': {
                  backgroundColor: 'var(--color-background)',
                  color: 'var(--color-text)',
                },
                '& .MuiInputLabel-root': {
                  color: 'var(--color-text-secondary)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--color-border)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--color-text)',
                },
              }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel sx={{ color: 'var(--color-text-secondary)' }}>
                Parent Classification
              </InputLabel>
              <Select
                value={formData.parentId}
                onChange={(e) => setFormData({ ...formData, parentId: e.target.value })}
                label="Parent Classification"
                sx={{ 
                  backgroundColor: 'var(--color-background)',
                  color: 'var(--color-text)',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-text)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-text)',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: 'var(--color-surface)',
                      '& .MuiMenuItem-root': {
                        color: 'var(--color-text)',
                      },
                      '& .MuiMenuItem-root:hover': {
                        backgroundColor: 'var(--color-hover)',
                      },
                    },
                  },
                }}
              >
                <MenuItem value="">None</MenuItem>
                {ifrsClassifications.map((classification) => (
                  <MenuItem key={classification.id} value={classification.id}>
                    {classification.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save Classification"}
            </Button>
          </Box>
        </Paper>
      )}

      {/* Edit Dialog */}
      <Dialog 
        open={editDialogOpen} 
        onClose={() => setEditDialogOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: 'var(--color-surface)',
            color: 'var(--color-text)',
          }
        }}
      >
        <DialogTitle sx={{ color: 'var(--color-text)' }}>
          Edit IFRS Classification
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
            sx={{ 
              mt: 2, 
              mb: 2,
              '& .MuiInputBase-root': {
                backgroundColor: 'var(--color-background)',
                color: 'var(--color-text)',
              },
              '& .MuiInputLabel-root': {
                color: 'var(--color-text-secondary)',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--color-border)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--color-text)',
              },
            }}
          />
          <TextField
            fullWidth
            label="Description"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            multiline
            rows={3}
            sx={{ 
              mb: 2,
              '& .MuiInputBase-root': {
                backgroundColor: 'var(--color-background)',
                color: 'var(--color-text)',
              },
              '& .MuiInputLabel-root': {
                color: 'var(--color-text-secondary)',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--color-border)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--color-text)',
              },
            }}
          />
          <FormControl fullWidth>
            <InputLabel sx={{ color: 'var(--color-text-secondary)' }}>
              Parent Classification
            </InputLabel>
            <Select
              value={formData.parentId}
              onChange={(e) => setFormData({ ...formData, parentId: e.target.value })}
              label="Parent Classification"
              sx={{ 
                backgroundColor: 'var(--color-background)',
                color: 'var(--color-text)',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--color-border)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--color-text)',
                },
                '& .MuiSvgIcon-root': {
                  color: 'var(--color-text)',
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: 'var(--color-surface)',
                    '& .MuiMenuItem-root': {
                      color: 'var(--color-text)',
                    },
                    '& .MuiMenuItem-root:hover': {
                      backgroundColor: 'var(--color-hover)',
                    },
                  },
                },
              }}
            >
              <MenuItem value="">None</MenuItem>
              {ifrsClassifications
                .filter(c => c.id !== selectedClassification?.id)
                .map((classification) => (
                  <MenuItem key={classification.id} value={classification.id}>
                    {classification.name}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} sx={{ color: 'var(--color-text)' }}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary" disabled={loading}>
            {loading ? "Saving..." : "Save Changes"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: 'var(--color-surface)',
            color: 'var(--color-text)',
          }
        }}
      >
        <DialogTitle sx={{ color: 'var(--color-text)' }}>
          Delete IFRS Classification
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'var(--color-text-secondary)' }}>
            Are you sure you want to delete this classification? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} sx={{ color: 'var(--color-text)' }}>
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default IFRSClassifications;
