import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Chip,
  InputAdornment,
  Alert,
  Autocomplete,
  Tooltip,
  Tabs,
  Tab,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Collapse,
  styled,
} from '@mui/material';
import { DataContext } from '../../contexts/DataContext';
import { ThemeContext } from '../../contexts/ThemeContext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SearchIcon from '@mui/icons-material/Search';
import { format } from 'date-fns';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { AuthContext } from '../../contexts/AuthContext';
import { createTransaction, updateTransaction, deleteTransaction } from '../../utils/api';

// Create styled components with dark mode support
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: 'var(--color-surface)',
    color: 'var(--color-text)',
    border: '1px solid var(--color-border)',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  },
}));

function Transactions() {
  const { accounts, currencies, contacts, transactions, transactionLines, tags, 
    fetchAccountsData, fetchCurrenciesData, fetchTransactionsData, 
    fetchTransactionLinesData, fetchContactsData, fetchTagsData } = useContext(DataContext);
  const { theme } = useContext(ThemeContext);
  const { accessToken } = useContext(AuthContext);

  // Tab state
  const [activeTab, setActiveTab] = useState(0);

  // Transaction form state
  const [transaction, setTransaction] = useState({
    id: null, // null for new transactions, UUID for existing ones
    date: format(new Date(), 'yyyy-MM-dd'), // Default to today
    description: '',
    lines: [],
    tags: [],
  });

  // Transaction list search and filter state
  const [searchTerm, setSearchTerm] = useState('');
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);

  const [totals, setTotals] = useState({ debit: 0, credit: 0 });
  const [error, setError] = useState(null);
  const [tagInput, setTagInput] = useState('');
  
  // Replace the hardcoded tag suggestions with a state that will be populated from the backend
  const [tagSuggestions, setTagSuggestions] = useState([]);

  const [baseCurrency, setBaseCurrency] = useState('USD'); // Default base currency
  const [openRow, setOpenRow] = useState({}); // Track which rows are expanded

  // Add loading and data fetch error state
  const [loading, setLoading] = useState(false);
  const [dataError, setDataError] = useState(null);

  // Add this at the start of the component, with other state variables
  const [transactionLinesMap, setTransactionLinesMap] = useState({});

  useEffect(() => {
    // Fetch all required data
    const loadData = async () => {
      setLoading(true);
      setDataError(null);
      try {
        await Promise.all([
          fetchAccountsData(),
          fetchContactsData(),
          fetchCurrenciesData(),
          fetchTransactionsData(),
          fetchTransactionLinesData(),
          fetchTagsData()
        ]);
      } catch (err) {
        console.error('Error loading transaction data:', err);
        setDataError(err.message || 'Failed to load transaction data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [fetchAccountsData, fetchContactsData, fetchCurrenciesData, fetchTransactionsData, fetchTransactionLinesData, fetchTagsData]);

  // Update useEffect to load tag suggestions from tags
  useEffect(() => {
    if (tags && Array.isArray(tags)) {
      const tagNames = tags.map(tag => tag.name);
      setTagSuggestions(tagNames);
    }
  }, [tags]);

  // Add retry functionality
  const handleRetryDataLoad = () => {
    const loadData = async () => {
      setLoading(true);
      setDataError(null);
      try {
        await Promise.all([
          fetchAccountsData(),
          fetchContactsData(),
          fetchCurrenciesData(),
          fetchTransactionsData(),
          fetchTransactionLinesData(),
          fetchTagsData()
        ]);
      } catch (err) {
        console.error('Error reloading transaction data:', err);
        setDataError(err.message || 'Failed to load transaction data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  };

  const handleAddLine = () => {
    setTransaction((prev) => ({
      ...prev,
      lines: [
        ...prev.lines,
        { account: '', contact: '', currency: baseCurrency, type: 'DEBIT', amount: 0, exchangeRate: 1, convertedAmount: 0 },
      ],
    }));
  };

  const handleLineChange = (index, field, value) => {
    const updatedLines = [...transaction.lines];
    updatedLines[index][field] = value;

    // Recalculate exchange amount if amount or exchange rate changes
    if (field === 'amount' || field === 'exchangeRate') {
      const line = updatedLines[index];
      line.convertedAmount = parseFloat(line.amount) / parseFloat(line.exchangeRate || 1);
    }

    // Update currency may require recalculating exchange rate and converted amount
    if (field === 'currency') {
      // Reset exchange rate to 1 if same as base currency
      if (value === baseCurrency) {
        updatedLines[index].exchangeRate = 1;
        updatedLines[index].convertedAmount = parseFloat(updatedLines[index].amount);
      } else {
        // Keep existing exchange rate but recalculate converted amount
        updatedLines[index].convertedAmount = parseFloat(updatedLines[index].amount) / parseFloat(updatedLines[index].exchangeRate || 1);
      }
    }

    setTransaction({ ...transaction, lines: updatedLines });
    calculateTotals(updatedLines);
  };

  const calculateTotals = (lines) => {
    let debit = 0;
    let credit = 0;

    lines.forEach((line) => {
      const convertedAmount = parseFloat(line.amount) / parseFloat(line.exchangeRate || 1);
      if (line.type === 'DEBIT') debit += convertedAmount || 0;
      if (line.type === 'CREDIT') credit += convertedAmount || 0;
    });

    setTotals({ debit, credit });
  };

  const handleSubmit = async () => {
    // Reset error state
    setError(null);
    
    // Validate transaction data
    if (!transaction.date) {
      setError("Transaction date is required");
      return;
    }
    
    if (!transaction.description) {
      setError("Transaction description is required");
      return;
    }
    
    if (transaction.lines.length === 0) {
      setError("At least one transaction line is required");
      return;
    }
    
    if (!isBalanced) {
      setError("Transaction must be balanced (debits must equal credits)");
      return;
    }
    
    // Validate each line has required fields
    for (let i = 0; i < transaction.lines.length; i++) {
      const line = transaction.lines[i];
      if (!line.account) {
        setError(`Line ${i+1}: Account is required`);
        return;
      }

      // Validate account is a valid UUID
      if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(line.account)) {
        setError(`Line ${i+1}: Invalid account ID format`);
        return;
      }
      
      if (!line.currency) {
        setError(`Line ${i+1}: Currency is required`);
        return;
      }
      
      if (!line.type) {
        setError(`Line ${i+1}: Type is required`);
        return;
      }
      
      if (!line.amount || isNaN(parseFloat(line.amount))) {
        setError(`Line ${i+1}: Valid amount is required`);
        return;
      }
    }

    try {
      setLoading(true);
      
      // Create a map of currency codes to IDs
      const currencyMap = {};
      if (currencies && Array.isArray(currencies)) {
        currencies.forEach(currency => {
          if (currency && currency.id && currency.code) {
            currencyMap[currency.code] = currency.id;
          }
        });
      }
      
      // Prepare the transaction data for API
      const transactionData = {
        transactionDate: transaction.date,
        description: transaction.description,
        lines: transaction.lines.map(line => {
          // Ensure we have a valid currency ID
          let currencyId = null;
          if (line.currency && currencyMap[line.currency]) {
            currencyId = currencyMap[line.currency];
          } else {
            // Use first available currency as fallback
            const firstCurrency = currencies && currencies.length > 0 ? currencies[0] : null;
            currencyId = firstCurrency ? firstCurrency.id : null;
          }
          
          // Validate currency ID is a valid UUID
          if (!currencyId || !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(currencyId)) {
            throw new Error(`Invalid currency ID for line with account ${line.account}`);
          }
          
          return {
            accountId: line.account,
            lineType: line.type,
            amount: parseFloat(line.amount) || 0,
            exchangeRate: parseFloat(line.exchangeRate) || 1,
            contactId: line.contact || null,
            currencyId: currencyId
          };
        }),
        tags: Array.isArray(transaction.tags) ? transaction.tags : []
      };
      
      // Import API_URL from the api.js file
      const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NAME}/api/${process.env.REACT_APP_API_VERSION}` || '/vanta-tracker/api/v1';
      
      let response;
      if (transaction.id) {
        // Update existing transaction
        response = await fetch(`${API_URL}/transactions/${transaction.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify(transactionData)
        });
      } else {
        // Create new transaction
        response = await fetch(`${API_URL}/transactions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify(transactionData)
        });
      }
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('API error:', response.status, errorText);
        
        try {
          // Try to parse as JSON if possible
          const errorData = JSON.parse(errorText);
          throw new Error(errorData.message || `Server returned ${response.status}`);
        } catch (parseError) {
          // If it's not valid JSON, use the text directly
          throw new Error(`Server error (${response.status}): ${errorText}`);
        }
      }
      
      const responseData = await response.json();
      
      // Reset form
      resetForm();
      
      // Refresh data
      await Promise.all([
        fetchTransactionsData(),
        fetchTransactionLinesData()
      ]);
      
      // Switch to transaction list tab
      setActiveTab(1);
      
    } catch (err) {
      console.error('Error submitting transaction:', err);
      setError(err.message || 'An unexpected error occurred while submitting the transaction');
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setTransaction({
      id: null,
      date: format(new Date(), 'yyyy-MM-dd'),
      description: '',
      lines: [],
      tags: [],
    });
    setTotals({ debit: 0, credit: 0 });
    setError(null);
  };

  const formatAmount = (amount, currency) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(amount);
  };

  // Handle adding a new tag
  const handleAddTag = (tag) => {
    if (tag && !transaction.tags.includes(tag)) {
      setTransaction({
        ...transaction,
        tags: [...transaction.tags, tag],
      });
      setTagInput('');
    }
  };

  // Handle removing a tag
  const handleDeleteTag = (tagToDelete) => {
    setTransaction({
      ...transaction,
      tags: transaction.tags.filter(tag => tag !== tagToDelete),
    });
  };

  // Check if debits and credits are balanced
  const isBalanced = Math.abs(totals.debit - totals.credit) < 0.01;

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Handle row expansion
  const handleRowToggle = (transactionId) => {
    setOpenRow((prev) => ({
      ...prev,
      [transactionId]: !prev[transactionId],
    }));
  };

  // Render transaction lines
  const renderTransactionLines = (transactionId) => {
    // Safely filter transaction lines - ensure both transactionLines exists and transactionId matches
    const lines = transactionLines && Array.isArray(transactionLines) ? 
      transactionLines.filter(line => line && line.transactionId === transactionId) : [];
    
    return (
      <Table size="small">
        <TableHead>
          <TableRow sx={{ backgroundColor: 'var(--color-surface)' }}>
            <TableCell sx={{ color: 'var(--color-text)' }}>Account</TableCell>
            <TableCell sx={{ color: 'var(--color-text)' }}>Contact</TableCell>
            <TableCell sx={{ color: 'var(--color-text)' }}>Currency</TableCell>
            <TableCell sx={{ color: 'var(--color-text)' }}>Type</TableCell>
            <TableCell sx={{ color: 'var(--color-text)' }}>Amount</TableCell>
            <TableCell sx={{ color: 'var(--color-text)' }}>Base Amount ({baseCurrency})</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lines.length === 0 ? (
            <TableRow sx={{ backgroundColor: 'var(--color-background)' }}>
              <TableCell colSpan={6} align="center" sx={{ color: 'var(--color-text)' }}>No transaction lines available</TableCell>
            </TableRow>
          ) : (
            lines.map((line) => {
              try {
                if (!line) return null;
                
                // Lookup related entities safely
                const account = accounts && Array.isArray(accounts) ? 
                  accounts.find(a => a && a.id === line.accountId) : null;
                
                const contact = contacts && Array.isArray(contacts) ? 
                  contacts.find(c => c && c.id === line.contactId) : null;
                
                const currency = currencies && Array.isArray(currencies) ? 
                  currencies.find(c => c && c.id === line.currencyId) : null;
                
                const exchangeRate = parseFloat(line.exchangeRate) || 1;
                const amount = parseFloat(line.amount) || 0;
                const baseAmount = amount / exchangeRate;

                return (
                  <TableRow key={line.id} sx={{ backgroundColor: 'var(--color-background)' }}>
                    <TableCell sx={{ color: 'var(--color-text)' }}>{account?.name || 'N/A'}</TableCell>
                    <TableCell sx={{ color: 'var(--color-text)' }}>{contact?.name || 'N/A'}</TableCell>
                    <TableCell sx={{ color: 'var(--color-text)' }}>{currency?.code || 'N/A'}</TableCell>
                    <TableCell sx={{ color: 'var(--color-text)' }}>{line.lineType || 'N/A'}</TableCell>
                    <TableCell sx={{ color: 'var(--color-text)' }}>
                      {formatAmount(amount, currency?.code || baseCurrency)}
                    </TableCell>
                    <TableCell sx={{ color: 'var(--color-text)' }}>
                      {formatAmount(baseAmount, baseCurrency)}
                    </TableCell>
                  </TableRow>
                );
              } catch (err) {
                console.error('Error rendering transaction line:', err, line);
                return (
                  <TableRow key={line?.id || 'error'} sx={{ backgroundColor: 'var(--color-background)' }}>
                    <TableCell colSpan={6} sx={{ color: 'error.main' }}>
                      Error displaying transaction line
                    </TableCell>
                  </TableRow>
                );
              }
            })
          )}
        </TableBody>
      </Table>
    );
  };

  // Update handleEditTransaction
  const handleEditTransaction = (transactionId) => {
    const transactionToEdit = transactions.find(t => t.id === transactionId);
    const linesForTransaction = transactionLines.filter(line => line.transactionId === transactionId);
    
    if (transactionToEdit) {
      // Extract tag names from tags array
      const tagNames = Array.isArray(transactionToEdit.tags) 
        ? transactionToEdit.tags.map(tag => tag.name) 
        : [];
      
      const formattedTransaction = {
        id: transactionToEdit.id,
        date: transactionToEdit.transactionDate,
        description: transactionToEdit.description,
        lines: linesForTransaction.map(line => {
          const currency = currencies.find(c => c.id === line.currencyId);
          return {
            id: line.id,
            account: line.accountId,
            contact: line.contactId,
            currency: currency?.code || baseCurrency,
            type: line.lineType || 'DEBIT',
            amount: line.amount,
            exchangeRate: line.exchangeRate || 1,
            convertedAmount: line.amount / (line.exchangeRate || 1)
          };
        }),
        tags: tagNames,
      };
      
      setTransaction(formattedTransaction);
      calculateTotals(formattedTransaction.lines);
      setActiveTab(0);
    }
  };

  // Update handleCloneTransaction
  const handleCloneTransaction = (transactionId) => {
    const transactionToClone = transactions.find(t => t.id === transactionId);
    const linesForTransaction = transactionLines.filter(line => line.transactionId === transactionId);
    
    if (transactionToClone) {
      // Extract tag names from tags array
      const tagNames = Array.isArray(transactionToClone.tags) 
        ? transactionToClone.tags.map(tag => tag.name) 
        : [];
        
      const clonedTransaction = {
        id: null,
        date: format(new Date(), 'yyyy-MM-dd'),
        description: transactionToClone.description,
        lines: linesForTransaction.map(line => {
          const currency = currencies.find(c => c.id === line.currencyId);
          return {
            account: line.accountId,
            contact: line.contactId,
            currency: currency?.code || baseCurrency,
            type: line.lineType || 'DEBIT',
            amount: line.amount,
            exchangeRate: line.exchangeRate || 1,
            convertedAmount: line.amount / (line.exchangeRate || 1)
          };
        }),
        tags: tagNames,
      };
      
      setTransaction(clonedTransaction);
      calculateTotals(clonedTransaction.lines);
      setActiveTab(0);
    }
  };

  // Open delete confirmation dialog
  const openDeleteDialog = (transactionId) => {
    setTransactionToDelete(transactionId);
    setConfirmDeleteOpen(true);
  };

  // Handle delete transaction
  const handleDeleteTransaction = async () => {
    if (transactionToDelete) {
      try {
        setLoading(true);
        
        // Import API_URL from the api.js file
        const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NAME}/api/${process.env.REACT_APP_API_VERSION}` || '/vanta-tracker/api/v1';
        
        // Make direct fetch request instead of using the utility function
        const response = await fetch(`${API_URL}/transactions/${transactionToDelete}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('API error:', response.status, errorText);
          
          try {
            // Try to parse as JSON if possible
            const errorData = JSON.parse(errorText);
            throw new Error(errorData.message || `Server returned ${response.status}`);
          } catch (parseError) {
            // If it's not valid JSON, use the text directly
            throw new Error(`Server error (${response.status}): ${errorText.substring(0, 100)}...`);
          }
        }

        // Refresh transactions list
        await Promise.all([
          fetchTransactionsData(),
          fetchTransactionLinesData()
        ]);

        setError(null);
      } catch (err) {
        console.error('Error deleting transaction:', err);
        setError(err.message || 'An error occurred while deleting the transaction');
      } finally {
        setLoading(false);
        
        // Close dialog
        setConfirmDeleteOpen(false);
        setTransactionToDelete(null);
      }
    }
  };

  // Filter transactions by search term
  const filteredTransactions = Array.isArray(transactions) ? 
    transactions.filter(transaction => 
      (transaction.description && transaction.description.toLowerCase().includes(searchTerm.toLowerCase())) || 
      (transaction.id && transaction.id.toLowerCase().includes(searchTerm.toLowerCase()))
    ) : [];

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: 'var(--color-background)',
        color: 'var(--color-text)',
        '& .MuiPopover-root .MuiPaper-root': {
          backgroundColor: 'var(--color-surface)',
          color: 'var(--color-text)',
        },
        '& .MuiMenuItem-root': {
          color: 'var(--color-text)',
        },
        '& .MuiMenuItem-root:hover': {
          backgroundColor: 'var(--color-hover)',
        },
        '& .MuiChip-root': {
          borderColor: 'var(--color-border)',
        },
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ color: 'var(--color-text)' }}>
        Transactions
      </Typography>

      {dataError && (
        <Alert severity="error" sx={{ mb: 3 }} action={
          <Button color="inherit" size="small" onClick={handleRetryDataLoad}>
            RETRY
          </Button>
        }>
          {dataError}
        </Alert>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <Typography sx={{ color: 'var(--color-text)' }}>Loading transaction data...</Typography>
        </Box>
      ) : (
        <>
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange} 
            sx={{ 
              mb: 3,
              '& .MuiTab-root': {
                color: 'var(--color-text-secondary)',
              },
              '& .Mui-selected': {
                color: 'var(--color-text)',
              },
              '& .MuiTabs-indicator': {
                backgroundColor: 'var(--color-text)',
              },
            }}
          >
            <Tab label="Add Transaction" />
            <Tab label="Transaction List" />
          </Tabs>

          {activeTab === 0 && (
            <>
              <Box sx={{ mb: 3 }}>
                <TextField
                  label="Transaction Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={transaction.date}
                  onChange={(e) =>
                    setTransaction({ ...transaction, date: e.target.value })
                  }
                  sx={{ 
                    mb: 2,
                    '& .MuiInputBase-root': {
                      backgroundColor: 'var(--color-surface)',
                      color: 'var(--color-text)',
                    },
                    '& .MuiInputLabel-root': {
                      color: 'var(--color-text-secondary)',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--color-border)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--color-text)',
                    },
                  }}
                />

                <TextField
                  label="Description"
                  fullWidth
                  multiline
                  rows={2}
                  value={transaction.description}
                  onChange={(e) =>
                    setTransaction({ ...transaction, description: e.target.value })
                  }
                  sx={{ 
                    '& .MuiInputBase-root': {
                      backgroundColor: 'var(--color-surface)',
                      color: 'var(--color-text)',
                    },
                    '& .MuiInputLabel-root': {
                      color: 'var(--color-text-secondary)',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--color-border)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--color-text)',
                    },
                  }}
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle1" sx={{ color: 'var(--color-text)' }}>Base Currency:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Select
                      value={baseCurrency}
                      onChange={(e) => setBaseCurrency(e.target.value)}
                      fullWidth
                      sx={{ 
                        backgroundColor: 'var(--color-surface)',
                        color: 'var(--color-text)',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'var(--color-border)',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'var(--color-text)',
                        },
                        '& .MuiSvgIcon-root': {
                          color: 'var(--color-text)',
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            backgroundColor: 'var(--color-surface)',
                          },
                        },
                      }}
                    >
                      {currencies.map((currency) => (
                        <MenuItem key={currency.id} value={currency.code}>
                          {currency.code} ({currency.symbol})
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Box>

              <TableContainer component={Paper} sx={{ backgroundColor: 'var(--color-surface)', mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: 'var(--color-text)' }}>Account</TableCell>
                      <TableCell sx={{ color: 'var(--color-text)' }}>Contact</TableCell>
                      <TableCell sx={{ color: 'var(--color-text)' }}>Currency</TableCell>
                      <TableCell sx={{ color: 'var(--color-text)' }}>Type</TableCell>
                      <TableCell sx={{ color: 'var(--color-text)' }}>Amount</TableCell>
                      <TableCell sx={{ color: 'var(--color-text)' }}>Exchange Rate</TableCell>
                      <TableCell sx={{ color: 'var(--color-text)' }}>Base Amount ({baseCurrency})</TableCell>
                      <TableCell sx={{ color: 'var(--color-text)' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transaction.lines.map((line, index) => (
                      <TableRow key={index} sx={{ 
                        '&:nth-of-type(odd)': {
                          backgroundColor: 'var(--color-background)',
                        },
                      }}>
                        <TableCell>
                          <Select
                            value={line.account}
                            onChange={(e) =>
                              handleLineChange(index, 'account', e.target.value)
                            }
                            fullWidth
                            sx={{ 
                              backgroundColor: 'var(--color-surface)',
                              color: 'var(--color-text)',
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-border)',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-text)',
                              },
                              '& .MuiSvgIcon-root': {
                                color: 'var(--color-text)',
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  backgroundColor: 'var(--color-surface)',
                                },
                              },
                            }}
                          >
                            {accounts.map((account) => (
                              <MenuItem key={account.id} value={account.id}>
                                {account.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Select
                            value={line.contact}
                            onChange={(e) =>
                              handleLineChange(index, 'contact', e.target.value)
                            }
                            fullWidth
                            sx={{ 
                              backgroundColor: 'var(--color-surface)',
                              color: 'var(--color-text)',
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-border)',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-text)',
                              },
                              '& .MuiSvgIcon-root': {
                                color: 'var(--color-text)',
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  backgroundColor: 'var(--color-surface)',
                                },
                              },
                            }}
                          >
                            {contacts.map((contact) => (
                              <MenuItem key={contact.id} value={contact.id}>
                                {contact.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Select
                            value={line.currency}
                            onChange={(e) =>
                              handleLineChange(index, 'currency', e.target.value)
                            }
                            fullWidth
                            sx={{ 
                              backgroundColor: 'var(--color-surface)',
                              color: 'var(--color-text)',
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-border)',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-text)',
                              },
                              '& .MuiSvgIcon-root': {
                                color: 'var(--color-text)',
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  backgroundColor: 'var(--color-surface)',
                                },
                              },
                            }}
                          >
                            {currencies.map((currency) => (
                              <MenuItem key={currency.id} value={currency.code}>
                                {currency.symbol}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Select
                            value={line.type}
                            onChange={(e) =>
                              handleLineChange(index, 'type', e.target.value)
                            }
                            fullWidth
                            sx={{ 
                              backgroundColor: 'var(--color-surface)',
                              color: 'var(--color-text)',
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-border)',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-text)',
                              },
                              '& .MuiSvgIcon-root': {
                                color: 'var(--color-text)',
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  backgroundColor: 'var(--color-surface)',
                                },
                              },
                            }}
                          >
                            <MenuItem value="DEBIT">Debit</MenuItem>
                            <MenuItem value="CREDIT">Credit</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            value={line.amount}
                            onChange={(e) =>
                              handleLineChange(index, 'amount', e.target.value)
                            }
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start" sx={{ color: 'var(--color-text)' }}>
                                  {currencies.find((c) => c.code === line.currency)?.symbol}
                                </InputAdornment>
                              ),
                            }}
                            sx={{ 
                              '& .MuiInputBase-root': {
                                backgroundColor: 'var(--color-surface)',
                                color: 'var(--color-text)',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-border)',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-text)',
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <StyledTooltip title={`1 ${line.currency} = ${line.exchangeRate} ${baseCurrency}`}>
                            <TextField
                              type="number"
                              value={line.exchangeRate}
                              onChange={(e) =>
                                handleLineChange(index, 'exchangeRate', e.target.value)
                              }
                              disabled={line.currency === baseCurrency}
                              fullWidth
                              sx={{ 
                                '& .MuiInputBase-root': {
                                  backgroundColor: 'var(--color-surface)',
                                  color: 'var(--color-text)',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'var(--color-border)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'var(--color-text)',
                                },
                                '& .Mui-disabled': {
                                  backgroundColor: 'var(--color-neutral)',
                                  opacity: 0.7,
                                },
                              }}
                            />
                          </StyledTooltip>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ color: 'var(--color-text)' }}>
                            {formatAmount(line.convertedAmount || line.amount / (line.exchangeRate || 1), baseCurrency)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Button
                            color="error"
                            onClick={() => {
                              const newLines = transaction.lines.filter((_, i) => i !== index);
                              setTransaction((prev) => ({
                                ...prev,
                                lines: newLines,
                              }));
                              calculateTotals(newLines);
                            }}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box sx={{ mt: 2, mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom sx={{ color: 'var(--color-text)' }}>Tags</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                  {transaction.tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      onDelete={() => handleDeleteTag(tag)}
                      color="primary"
                      variant="outlined"
                    />
                  ))}
                </Box>
                <Autocomplete
                  freeSolo
                  value={tagInput}
                  onChange={(event, newValue) => {
                    if (newValue) handleAddTag(newValue);
                  }}
                  inputValue={tagInput}
                  onInputChange={(event, newInputValue) => {
                    setTagInput(newInputValue);
                  }}
                  options={tagSuggestions.filter(tag => !transaction.tags.includes(tag))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Add Tags"
                      placeholder="Type and press Enter"
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && tagInput) {
                          e.preventDefault();
                          handleAddTag(tagInput);
                        }
                      }}
                      sx={{ 
                        '& .MuiInputBase-root': {
                          backgroundColor: 'var(--color-surface)',
                          color: 'var(--color-text)',
                        },
                        '& .MuiInputLabel-root': {
                          color: 'var(--color-text-secondary)',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'var(--color-border)',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'var(--color-text)',
                        },
                      }}
                    />
                  )}
                  sx={{ 
                    '& .MuiAutocomplete-option': {
                      color: 'var(--color-text)',
                    },
                  }}
                  ListboxProps={{
                    style: {
                      backgroundColor: 'var(--color-surface)',
                    },
                  }}
                  PaperProps={{
                    sx: {
                      backgroundColor: 'var(--color-surface)',
                      '& .MuiAutocomplete-option': {
                        '&:hover': {
                          backgroundColor: 'var(--color-hover)',
                        },
                      },
                    },
                  }}
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <Button 
                  variant="contained" 
                  onClick={handleAddLine} 
                  sx={{ 
                    mr: 2,
                    backgroundColor: 'var(--color-surface)',
                    color: 'var(--color-text)',
                    borderColor: 'var(--color-border)',
                    '&:hover': {
                      backgroundColor: 'var(--color-hover)',
                    },
                  }}
                >
                  Add Line
                </Button>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleSubmit} 
                  disabled={!isBalanced}
                >
                  {transaction.id ? 'Update Transaction' : 'Submit Transaction'}
                </Button>
                {transaction.id && (
                  <Button 
                    variant="outlined" 
                    color="secondary" 
                    onClick={resetForm} 
                    sx={{ 
                      ml: 2,
                      borderColor: 'var(--color-border)',
                      color: 'var(--color-text)',
                      '&:hover': {
                        borderColor: 'var(--color-text)',
                      },
                    }}
                  >
                    Cancel Edit
                  </Button>
                )}
              </Box>

              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}

              <Box sx={{ mt: 3, p: 2, backgroundColor: 'var(--color-surface)', borderRadius: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle1" sx={{ color: 'var(--color-text)' }}>Total Debit:</Typography>
                    <Typography variant="h6" sx={{ color: 'var(--color-text)' }}>{formatAmount(totals.debit, baseCurrency)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle1" sx={{ color: 'var(--color-text)' }}>Total Credit:</Typography>
                    <Typography variant="h6" sx={{ color: 'var(--color-text)' }}>{formatAmount(totals.credit, baseCurrency)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle1" sx={{ color: 'var(--color-text)' }}>Balance:</Typography>
                    <Alert severity={isBalanced ? "success" : "warning"} sx={{ mt: 1 }}>
                      {isBalanced 
                        ? "Balanced ✓" 
                        : `Difference: ${formatAmount(Math.abs(totals.debit - totals.credit), baseCurrency)}`}
                    </Alert>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}

          {activeTab === 1 && (
            <>
              <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
                <TextField
                  label="Search Transactions"
                  variant="outlined"
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ color: 'var(--color-text)' }}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ 
                    '& .MuiInputBase-root': {
                      backgroundColor: 'var(--color-surface)',
                      color: 'var(--color-text)',
                    },
                    '& .MuiInputLabel-root': {
                      color: 'var(--color-text-secondary)',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--color-border)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--color-text)',
                    },
                  }}
                />
                <Button 
                  variant="contained" 
                  color="primary" 
                  sx={{ ml: 2, height: 56 }}
                  onClick={() => {
                    resetForm();
                    setActiveTab(0);
                  }}
                >
                  New Transaction
                </Button>
              </Box>

              <TableContainer component={Paper} sx={{ backgroundColor: 'var(--color-surface)', mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox" />
                      <TableCell sx={{ color: 'var(--color-text)' }}>Date</TableCell>
                      <TableCell sx={{ color: 'var(--color-text)' }}>Description</TableCell>
                      <TableCell sx={{ color: 'var(--color-text)' }}>Amount</TableCell>
                      <TableCell sx={{ color: 'var(--color-text)' }}>Tags</TableCell>
                      <TableCell sx={{ color: 'var(--color-text)' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTransactions.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center" sx={{ color: 'var(--color-text)' }}>
                          No transactions found
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredTransactions.map((transactionItem) => {
                        const transactionLineItems = transactionLines && Array.isArray(transactionLines) ? 
                          transactionLines.filter(line => line && line.transactionId === transactionItem.id) : [];
                        
                        // Use totalAmount from the backend if available, otherwise calculate it locally
                        const totalAmount = transactionItem.totalAmount !== undefined 
                          ? transactionItem.totalAmount 
                          : transactionLineItems.reduce((total, line) => {
                              if (!line) return total;
                              try {
                                if (line.lineType === 'DEBIT') {
                                  return total + parseFloat(line.amount || 0) / (parseFloat(line.exchangeRate || 1));
                                }
                                return total;
                              } catch (err) {
                                console.error(`Error calculating amount for line in transaction ${transactionItem.id}:`, err);
                                return total;
                              }
                            }, 0);
                        
                        return (
                          <React.Fragment key={transactionItem.id}>
                            <TableRow sx={{ 
                              '&:nth-of-type(odd)': {
                                backgroundColor: 'var(--color-background)',
                              },
                            }}>
                              <TableCell padding="checkbox">
                                <IconButton
                                  size="small"
                                  onClick={() => handleRowToggle(transactionItem.id)}
                                  sx={{ color: 'var(--color-icon)' }}
                                >
                                  {openRow[transactionItem.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>
                              </TableCell>
                              <TableCell sx={{ color: 'var(--color-text)' }}>{transactionItem.transactionDate}</TableCell>
                              <TableCell sx={{ color: 'var(--color-text)' }}>{transactionItem.description}</TableCell>
                              <TableCell sx={{ color: 'var(--color-text)' }}>{formatAmount(totalAmount, baseCurrency)}</TableCell>
                              <TableCell>
                                {transactionItem.tags && transactionItem.tags.length > 0 ? (
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {transactionItem.tags.map((tag, index) => (
                                      <Chip 
                                        key={index} 
                                        label={tag.name} 
                                        size="small" 
                                        color="primary" 
                                        variant="outlined" 
                                      />
                                    ))}
                                  </Box>
                                ) : (
                                  <span style={{ color: 'var(--color-text-secondary)' }}>No tags</span>
                                )}
                              </TableCell>
                              <TableCell>
                                <IconButton 
                                  color="primary"
                                  onClick={() => handleEditTransaction(transactionItem.id)}
                                  title="Edit transaction"
                                  sx={{ color: 'var(--color-icon)' }}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton 
                                  color="secondary"
                                  onClick={() => handleCloneTransaction(transactionItem.id)}
                                  title="Clone transaction"
                                  sx={{ color: 'var(--color-icon)' }}
                                >
                                  <ContentCopyIcon />
                                </IconButton>
                                <IconButton 
                                  color="error"
                                  onClick={() => openDeleteDialog(transactionItem.id)}
                                  title="Delete transaction"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={openRow[transactionItem.id]} timeout="auto" unmountOnExit>
                                  <Box sx={{ margin: 1 }}>
                                    {renderTransactionLines(transactionItem.id)}
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          {/* Delete Confirmation Dialog */}
          <Dialog
            open={confirmDeleteOpen}
            onClose={() => setConfirmDeleteOpen(false)}
            PaperProps={{
              sx: {
                backgroundColor: 'var(--color-surface)',
                color: 'var(--color-text)',
              },
            }}
          >
            <DialogTitle sx={{ color: 'var(--color-text)' }}>Delete Transaction</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ color: 'var(--color-text-secondary)' }}>
                Are you sure you want to delete this transaction? This action cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmDeleteOpen(false)} sx={{ color: 'var(--color-text)' }}>Cancel</Button>
              <Button onClick={handleDeleteTransaction} color="error">Delete</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
}

export default Transactions;
