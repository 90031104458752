import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../contexts/DataContext';
import { ThemeContext } from '../../contexts/ThemeContext';
import { AuthContext } from '../../contexts/AuthContext';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
} from '@mui/material';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { createAccount, updateAccount, deleteAccount, refreshAccessToken } from '../../utils/api';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function Accounts() {
  const { 
    accounts, 
    currencies, 
    contacts, 
    ifrsClassifications, 
    fetchAccountsData, 
    fetchCurrenciesData, 
    fetchContactsData,
    fetchIFRSClassificationsData 
  } = useContext(DataContext);
  const { theme } = useContext(ThemeContext);
  const { accessToken, setAccessToken, logout, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Dialog states
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);

  // Form state
  const [formData, setFormData] = useState({
    name: '',
    type: 'ASSET',
    currencyId: '',
    contactId: '',
    ifrsClassificationId: '',
    currentBalance: 0,
  });

  // Validation errors
  const [formErrors, setFormErrors] = useState({});

  // Enhanced useEffect to extract relevant data from API response and prepare for display
  const [enhancedAccounts, setEnhancedAccounts] = useState([]);
  
  // Handle token refresh
  const handleTokenRefresh = async () => {
    try {
      const data = await refreshAccessToken();
      if (data && data.accessToken) {
        setAccessToken(data.accessToken);
        return data.accessToken;
      }
      throw new Error('No token received');
    } catch (err) {
      console.error('Token refresh failed:', err);
      logout(); // Force logout on failed refresh
      return null;
    }
  };
  
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        setError(null);
        // Fetch all required data
        await Promise.all([
          fetchAccountsData(),
          fetchCurrenciesData(),
          fetchContactsData(),
          fetchIFRSClassificationsData()
        ]);
      } catch (err) {
        console.error('Error loading data:', err);
        // Try to refresh token if unauthorized
        if (err.message && err.message.includes('401')) {
          try {
            const newToken = await handleTokenRefresh();
            if (newToken) {
              // Retry with new token
              await Promise.all([
                fetchAccountsData(),
                fetchCurrenciesData(),
                fetchContactsData(),
                fetchIFRSClassificationsData()
              ]);
            } else {
              setError('Session expired. Please log in again.');
            }
          } catch (refreshErr) {
            setError('Authentication failed. Please log in again.');
          }
        } else {
          setError('Failed to load data. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [fetchAccountsData, fetchCurrenciesData, fetchContactsData, fetchIFRSClassificationsData]);

  useEffect(() => {
    if (!accounts || !currencies || !contacts || !ifrsClassifications) {
      setEnhancedAccounts([]);
      return;
    }
    
    try {
      // Create flattened account objects with directly accessible fields
      const flattenedAccounts = accounts.map(account => {
        // Find the related objects
        const currency = account.currency || currencies.find(c => c.id === account.currencyId);
        const contact = account.contact || contacts.find(c => c.id === account.contactId);
        const ifrsClass = account.ifrsClassification || ifrsClassifications.find(c => c.id === account.ifrsClassificationId);
        
        // Get balance, ensuring it's a number
        let balance = 0;
        if (account.currentBalance !== undefined) {
          balance = Number(account.currentBalance);
        } else if (account.current_balance !== undefined) {
          balance = Number(account.current_balance);
        }
        
        // Create a flattened object with all the required display properties
        return {
          ...account,
          // Ensure currentBalance is a number
          currentBalance: balance,
          // Preserve original objects as well as adding flattened properties
          currencyCode: currency ? currency.code : 'N/A',
          currencySymbol: currency ? currency.symbol : '$',
          contactName: contact ? contact.name : 'N/A',
          ifrsName: ifrsClass ? ifrsClass.name : 'N/A'
        };
      });
      
      setEnhancedAccounts(flattenedAccounts);
    } catch (err) {
      console.error('Error in accounts data mapping:', err);
      setEnhancedAccounts([]);
    }
  }, [accounts, currencies, contacts, ifrsClassifications]);

  const handleCreateAccount = async () => {
    if (!validateForm()) return;
    
    try {
      setLoading(true);
      
      // Get userId from context, with fallback to localStorage 
      const userId = user?.id || localStorage.getItem('userId');
      
      if (!userId) {
        throw new Error('User ID not found. Please log in again.');
      }
      
      // Add the current user's ID to the form data
      const accountData = {
        ...formData,
        userId: userId
      };
      
      await createAccount(accessToken, accountData);
      await fetchAccountsData(); // Refresh accounts list
      setSnackbar({ open: true, message: 'Account created successfully', severity: 'success' });
      setCreateDialogOpen(false);
      resetForm();
    } catch (err) {
      console.error('Error creating account:', err);
      setSnackbar({ open: true, message: err.message || 'Failed to create account', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateAccount = async () => {
    if (!validateForm() || !currentAccount) return;
    
    try {
      setLoading(true);
      
      // Get userId from context, with fallback to localStorage 
      const userId = user?.id || localStorage.getItem('userId');
      
      if (!userId) {
        throw new Error('User ID not found. Please log in again.');
      }
      
      // Ensure userId is included in update data
      const accountData = {
        ...formData,
        userId: userId
      };
      
      await updateAccount(accessToken, currentAccount.id, accountData);
      await fetchAccountsData(); // Refresh accounts list
      setSnackbar({ open: true, message: 'Account updated successfully', severity: 'success' });
      setEditDialogOpen(false);
      resetForm();
    } catch (err) {
      console.error('Error updating account:', err);
      setSnackbar({ open: true, message: err.message || 'Failed to update account', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (!currentAccount) return;
    
    try {
      setLoading(true);
      await deleteAccount(accessToken, currentAccount.id);
      await fetchAccountsData(); // Refresh accounts list
      setSnackbar({ open: true, message: 'Account deleted successfully', severity: 'success' });
      setDeleteDialogOpen(false);
    } catch (err) {
      console.error('Error deleting account:', err);
      setSnackbar({ open: true, message: err.message || 'Failed to delete account', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const openEditDialog = (account) => {
    setCurrentAccount(account);
    setFormData({
      name: account.name || '',
      type: account.type || 'ASSET',
      currencyId: account.currencyId || '',
      contactId: account.contactId || '',
      ifrsClassificationId: account.ifrsClassificationId || '',
      currentBalance: account.currentBalance || 0,
    });
    setEditDialogOpen(true);
  };

  const openDeleteDialog = (account) => {
    setCurrentAccount(account);
    setDeleteDialogOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    
    // Clear error for this field when user types
    if (formErrors[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: null,
      }));
    }
  };

  const resetForm = () => {
    setFormData({
      name: '',
      type: 'ASSET',
      currencyId: '',
      contactId: '',
      ifrsClassificationId: '',
      currentBalance: 0,
    });
    setFormErrors({});
  };

  const validateForm = () => {
    const errors = {};
    
    if (!formData.name.trim()) {
      errors.name = 'Account name is required';
    }
    
    if (!formData.type) {
      errors.type = 'Account type is required';
    }
    
    if (formData.currentBalance === '' || isNaN(Number(formData.currentBalance))) {
      errors.currentBalance = 'Current balance must be a valid number';
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Define DataGrid Columns
  const columns = [
    { 
      field: 'name', 
      headerName: 'Account Name', 
      width: 200, 
      flex: 1,
      editable: false
    },
    { 
      field: 'type', 
      headerName: 'Type', 
      width: 150,
      editable: false
    },
    {
      field: 'currencyCode', 
      headerName: 'Currency', 
      width: 120,
      editable: false
    },
    { 
      field: 'currentBalance', 
      headerName: 'Balance', 
      width: 150,
      type: 'number',
      editable: false,
      // Instead of separate valueGetter/valueFormatter, use renderCell to handle both
      renderCell: (params) => {
        if (!params || !params.row) return 'N/A';
        
        try {
          // Directly access the balance value from row data
          const balanceValue = params.row.currentBalance;
          
          if (balanceValue === null || balanceValue === undefined) {
            return 'N/A';
          }
          
          // Format the number with currency symbol
          const numValue = Number(balanceValue);
          const symbol = params.row.currencySymbol || '$';
          return `${symbol}${numValue.toFixed(2)}`;
        } catch (err) {
          console.error('Error rendering balance:', err);
          return 'Error';
        }
      }
    },
    { 
      field: 'contactName', // Use the flattened property
      headerName: 'Contact', 
      width: 180,
      editable: false
    },
    { 
      field: 'ifrsName', // Use the flattened property
      headerName: 'IFRS Classification', 
      width: 200,
      editable: false
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: (params) => {
        if (!params || !params.row) return [];
        
        try {
          return [
            <GridActionsCellItem
              icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
              label="Edit"
              onClick={() => openEditDialog(params.row)}
              sx={{ color: 'var(--color-icon)' }}
            />,
            <GridActionsCellItem
              icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
              label="Delete"
              onClick={() => openDeleteDialog(params.row)}
              sx={{ color: 'var(--color-icon)' }}
            />
          ];
        } catch (err) {
          console.error('Error in actions getActions:', err);
          return [];
        }
      }
    },
  ];

  // Render loading spinner
  if (loading && accounts.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <CircularProgress sx={{ color: 'var(--color-text)' }} />
      </Box>
    );
  }

  // Render error message
  if (error && accounts.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: 'var(--color-background)',
        color: 'var(--color-text)',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography
          variant="h4"
          sx={{
            color: 'var(--color-text)',
          }}
        >
          Accounts
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            resetForm();
            setCreateDialogOpen(true);
          }}
          sx={{
            backgroundColor: 'var(--color-surface)',
            color: 'var(--color-text)',
            '&:hover': {
              backgroundColor: 'var(--color-hover)',
            },
          }}
        >
          Add Account
        </Button>
      </Box>

      <DataGrid
        rows={enhancedAccounts}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 25]}
        autoHeight
        loading={loading}
        getRowId={(row) => row.id}
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
        components={{
          Toolbar: GridToolbar,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        experimentalFeatures={{ columnReordering: true }}
        sx={{
          mt: 2,
          border: '1px solid var(--color-border)',
          backgroundColor: 'var(--color-surface)',
          color: 'var(--color-text)',
          width: '100%',
          '& .MuiDataGrid-toolbarContainer': {
            backgroundColor: 'var(--color-surface)',
            padding: '8px',
          },
          '& .MuiDataGrid-columnHeaders': {
            cursor: 'move', // Show move cursor on column headers
            userSelect: 'none',
            backgroundColor: 'var(--color-surface)',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            color: 'var(--color-text)',
          },
          '& .MuiButtonBase-root': {
            color: 'var(--color-text)',
          },
          '& .MuiDataGrid-main': {
            width: '100%',
          },
          '& .MuiDataGrid-row': {
            backgroundColor: 'var(--color-surface)',
            color: 'var(--color-text)',
            '&:hover': {
              backgroundColor: 'var(--color-hover)',
            },
          },
          '& .MuiDataGrid-menuList': {
            backgroundColor: 'var(--color-surface)',
            color: 'var(--color-text)',
          },
          '& .MuiDataGrid-container--top, & .MuiDataGrid-row--borderBottom': {
            backgroundColor: 'var(--color-surface) !important',
            background: 'var(--color-surface) !important',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: 'var(--color-surface)',
            color: 'var(--color-text)',
            borderTop: '1px solid var(--color-border)',
          },
          '& .MuiTablePagination-toolbar': {
            color: 'var(--color-text)',
          },
          '& .MuiDataGrid-cell': {
            color: 'var(--color-text)',
          },
          '& .MuiDataGrid-overlay': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
          '& .MuiMenu-paper, & .MuiPopover-paper': {
            backgroundColor: 'var(--color-surface)',
            color: 'var(--color-text)',
          },
          '& .MuiMenuItem-root': {
            color: 'var(--color-text)',
            '&:hover': {
              backgroundColor: 'var(--color-hover)',
            },
          },
        }}
      />

      {/* Create Account Dialog */}
      <Dialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: 'var(--color-surface)',
            color: 'var(--color-text)',
          },
        }}
      >
        <DialogTitle sx={{ color: 'var(--color-text)' }}>Create New Account</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              autoFocus
              name="name"
              label="Account Name"
              fullWidth
              value={formData.name}
              onChange={handleInputChange}
              margin="dense"
              error={!!formErrors.name}
              helperText={formErrors.name}
              sx={{ 
                mb: 2,
                '& .MuiInputBase-root': {
                  backgroundColor: 'var(--color-surface)',
                  color: 'var(--color-text)',
                },
                '& .MuiInputLabel-root': {
                  color: 'var(--color-text-secondary)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--color-border)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--color-text)',
                },
              }}
            />
            
            <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
              <InputLabel sx={{ color: 'var(--color-text-secondary)' }}>Account Type</InputLabel>
              <Select
                name="type"
                value={formData.type}
                onChange={handleInputChange}
                error={!!formErrors.type}
                sx={{ 
                  backgroundColor: 'var(--color-surface)',
                  color: 'var(--color-text)',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-text)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-text)',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: 'var(--color-surface)',
                    },
                  },
                }}
              >
                <MenuItem value="ASSET">Asset</MenuItem>
                <MenuItem value="LIABILITY">Liability</MenuItem>
                <MenuItem value="EQUITY">Equity</MenuItem>
                <MenuItem value="EXPENSE">Expense</MenuItem>
                <MenuItem value="INCOME">Income</MenuItem>
              </Select>
              {formErrors.type && (
                <Typography color="error" variant="caption">
                  {formErrors.type}
                </Typography>
              )}
            </FormControl>
            
            <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
              <InputLabel sx={{ color: 'var(--color-text-secondary)' }}>Currency</InputLabel>
              <Select
                name="currencyId"
                value={formData.currencyId}
                onChange={handleInputChange}
                sx={{ 
                  backgroundColor: 'var(--color-surface)',
                  color: 'var(--color-text)',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-text)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-text)',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: 'var(--color-surface)',
                    },
                  },
                }}
              >
                <MenuItem value="">None</MenuItem>
                {currencies.map((currency) => (
                  <MenuItem key={currency.id} value={currency.id}>
                    {currency.code} ({currency.symbol})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
              <InputLabel sx={{ color: 'var(--color-text-secondary)' }}>Contact</InputLabel>
              <Select
                name="contactId"
                value={formData.contactId}
                onChange={handleInputChange}
                sx={{ 
                  backgroundColor: 'var(--color-surface)',
                  color: 'var(--color-text)',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-text)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-text)',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: 'var(--color-surface)',
                    },
                  },
                }}
              >
                <MenuItem value="">None</MenuItem>
                {contacts.map((contact) => (
                  <MenuItem key={contact.id} value={contact.id}>
                    {contact.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
              <InputLabel sx={{ color: 'var(--color-text-secondary)' }}>IFRS Classification</InputLabel>
              <Select
                name="ifrsClassificationId"
                value={formData.ifrsClassificationId}
                onChange={handleInputChange}
                sx={{ 
                  backgroundColor: 'var(--color-surface)',
                  color: 'var(--color-text)',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-text)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-text)',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: 'var(--color-surface)',
                    },
                  },
                }}
              >
                <MenuItem value="">None</MenuItem>
                {ifrsClassifications.map((classification) => (
                  <MenuItem key={classification.id} value={classification.id}>
                    {classification.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <TextField
              name="currentBalance"
              label="Current Balance"
              type="number"
              fullWidth
              value={formData.currentBalance}
              onChange={handleInputChange}
              margin="dense"
              error={!!formErrors.currentBalance}
              helperText={formErrors.currentBalance}
              sx={{ 
                mb: 2,
                '& .MuiInputBase-root': {
                  backgroundColor: 'var(--color-surface)',
                  color: 'var(--color-text)',
                },
                '& .MuiInputLabel-root': {
                  color: 'var(--color-text-secondary)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--color-border)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--color-text)',
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setCreateDialogOpen(false)} 
            sx={{ color: 'var(--color-text-secondary)' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleCreateAccount} 
            color="primary"
            disabled={loading}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Account Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: 'var(--color-surface)',
            color: 'var(--color-text)',
          },
        }}
      >
        <DialogTitle sx={{ color: 'var(--color-text)' }}>Edit Account</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              autoFocus
              name="name"
              label="Account Name"
              fullWidth
              value={formData.name}
              onChange={handleInputChange}
              margin="dense"
              error={!!formErrors.name}
              helperText={formErrors.name}
              sx={{ 
                mb: 2,
                '& .MuiInputBase-root': {
                  backgroundColor: 'var(--color-surface)',
                  color: 'var(--color-text)',
                },
                '& .MuiInputLabel-root': {
                  color: 'var(--color-text-secondary)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--color-border)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--color-text)',
                },
              }}
            />
            
            <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
              <InputLabel sx={{ color: 'var(--color-text-secondary)' }}>Account Type</InputLabel>
              <Select
                name="type"
                value={formData.type}
                onChange={handleInputChange}
                error={!!formErrors.type}
                sx={{ 
                  backgroundColor: 'var(--color-surface)',
                  color: 'var(--color-text)',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-text)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-text)',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: 'var(--color-surface)',
                    },
                  },
                }}
              >
                <MenuItem value="ASSET">Asset</MenuItem>
                <MenuItem value="LIABILITY">Liability</MenuItem>
                <MenuItem value="EQUITY">Equity</MenuItem>
                <MenuItem value="EXPENSE">Expense</MenuItem>
                <MenuItem value="INCOME">Income</MenuItem>
              </Select>
              {formErrors.type && (
                <Typography color="error" variant="caption">
                  {formErrors.type}
                </Typography>
              )}
            </FormControl>
            
            <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
              <InputLabel sx={{ color: 'var(--color-text-secondary)' }}>Currency</InputLabel>
              <Select
                name="currencyId"
                value={formData.currencyId}
                onChange={handleInputChange}
                sx={{ 
                  backgroundColor: 'var(--color-surface)',
                  color: 'var(--color-text)',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-text)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-text)',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: 'var(--color-surface)',
                    },
                  },
                }}
              >
                <MenuItem value="">None</MenuItem>
                {currencies.map((currency) => (
                  <MenuItem key={currency.id} value={currency.id}>
                    {currency.code} ({currency.symbol})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
              <InputLabel sx={{ color: 'var(--color-text-secondary)' }}>Contact</InputLabel>
              <Select
                name="contactId"
                value={formData.contactId}
                onChange={handleInputChange}
                sx={{ 
                  backgroundColor: 'var(--color-surface)',
                  color: 'var(--color-text)',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-text)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-text)',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: 'var(--color-surface)',
                    },
                  },
                }}
              >
                <MenuItem value="">None</MenuItem>
                {contacts.map((contact) => (
                  <MenuItem key={contact.id} value={contact.id}>
                    {contact.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
              <InputLabel sx={{ color: 'var(--color-text-secondary)' }}>IFRS Classification</InputLabel>
              <Select
                name="ifrsClassificationId"
                value={formData.ifrsClassificationId}
                onChange={handleInputChange}
                sx={{ 
                  backgroundColor: 'var(--color-surface)',
                  color: 'var(--color-text)',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-text)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-text)',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: 'var(--color-surface)',
                    },
                  },
                }}
              >
                <MenuItem value="">None</MenuItem>
                {ifrsClassifications.map((classification) => (
                  <MenuItem key={classification.id} value={classification.id}>
                    {classification.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <TextField
              name="currentBalance"
              label="Current Balance"
              type="number"
              fullWidth
              value={formData.currentBalance}
              onChange={handleInputChange}
              margin="dense"
              error={!!formErrors.currentBalance}
              helperText={formErrors.currentBalance}
              sx={{ 
                mb: 2,
                '& .MuiInputBase-root': {
                  backgroundColor: 'var(--color-surface)',
                  color: 'var(--color-text)',
                },
                '& .MuiInputLabel-root': {
                  color: 'var(--color-text-secondary)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--color-border)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--color-text)',
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setEditDialogOpen(false)} 
            sx={{ color: 'var(--color-text-secondary)' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleUpdateAccount} 
            color="primary"
            disabled={loading}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: 'var(--color-surface)',
            color: 'var(--color-text)',
          },
        }}
      >
        <DialogTitle sx={{ color: 'var(--color-text)' }}>Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'var(--color-text-secondary)' }}>
            Are you sure you want to delete the account "{currentAccount?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteDialogOpen(false)} 
            sx={{ color: 'var(--color-text-secondary)' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteAccount} 
            color="error"
            disabled={loading}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Accounts;
