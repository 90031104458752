import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../contexts/DataContext';
import { ThemeContext } from '../../contexts/ThemeContext';
import { AuthContext } from '../../contexts/AuthContext';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
  Paper,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Autocomplete
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { createContact, updateContact, deleteContact, refreshAccessToken } from '../../utils/api';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function Contacts() {
  const { contacts, fetchContactsData } = useContext(DataContext);
  const { theme } = useContext(ThemeContext);
  const { accessToken, setAccessToken, logout, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Dialog states
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);

  // Form state
  const [formData, setFormData] = useState({
    userId: '',
    name: '',
    category: 'general',
    email: '',
    phone: '',
    address: '',
    notes: ''
  });

  // Validation errors
  const [formErrors, setFormErrors] = useState({});

  // Derive available categories from existing contacts + add 'general'
  const [availableCategories, setAvailableCategories] = useState(['general']);

  useEffect(() => {
    if (contacts && contacts.length > 0) {
      const uniqueCategories = [...new Set(contacts.map(c => c.category).filter(Boolean))];
      // Ensure 'general' is always included
      if (!uniqueCategories.includes('general')) {
        uniqueCategories.push('general');
      }
      setAvailableCategories(uniqueCategories.sort());
    } else {
      setAvailableCategories(['general']);
    }
  }, [contacts]);

  // Handle token refresh
  const handleTokenRefresh = async () => {
    try {
      const data = await refreshAccessToken();
      if (data && data.access_token) {
        setAccessToken(data.access_token);
        return data.access_token;
      }
      throw new Error('No token received');
    } catch (err) {
      console.error('Token refresh failed:', err);
      logout(); // Force logout on failed refresh
      return null;
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        setError(null);
        await fetchContactsData();
      } catch (err) {
        console.error('Error loading data:', err);
        if (err.message && err.message.includes('401')) {
          try {
            const newToken = await handleTokenRefresh();
            if (newToken) {
              await fetchContactsData(); // Retry with new token
            } else {
              setError('Session expired. Please log in again.');
            }
          } catch (refreshErr) {
            setError('Authentication failed. Please log in again.');
          }
        } else {
          setError('Failed to load contacts. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [fetchContactsData]); // Only refetch when fetchContactsData changes

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.category) errors.category = 'Category is required';
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) errors.email = 'Invalid email format';
    if (formData.phone && !/^\+?[0-9]{7,15}$/.test(formData.phone)) errors.phone = 'Invalid phone number format (e.g., +1234567890)';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };

  // Specific handler for Autocomplete changes
  const handleCategoryChange = (event, newValue) => {
    setFormData({ ...formData, category: newValue || '' });
    if (formErrors.category) {
      setFormErrors({ ...formErrors, category: '' });
    }
  };

  const resetForm = () => {
    setFormData({
      userId: '',
      name: '',
      category: 'general',
      email: '',
      phone: '',
      address: '',
      notes: ''
    });
    setFormErrors({});
    setCurrentContact(null);
  };

  const handleCreateContact = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      const { userId, ...contactData } = formData;
      await createContact(accessToken, contactData);
      await fetchContactsData(); // Refresh list
      setSnackbar({ open: true, message: 'Contact created successfully', severity: 'success' });
      setCreateDialogOpen(false);
      resetForm();
    } catch (err) {
      console.error('Error creating contact:', err);
      const errorMessage = err.response?.data?.message || err.message || 'Failed to create contact';
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateContact = async () => {
    if (!validateForm() || !currentContact) return;

    try {
      setLoading(true);
      const { userId, ...contactData } = formData;
      await updateContact(accessToken, currentContact.id, contactData);
      await fetchContactsData(); // Refresh list
      setSnackbar({ open: true, message: 'Contact updated successfully', severity: 'success' });
      setEditDialogOpen(false);
      resetForm();
    } catch (err) {
      console.error('Error updating contact:', err);
      const errorMessage = err.response?.data?.message || err.message || 'Failed to update contact';
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteContact = async () => {
    if (!currentContact) return;

    try {
      setLoading(true);
      await deleteContact(accessToken, currentContact.id);
      await fetchContactsData(); // Refresh list
      setSnackbar({ open: true, message: 'Contact deleted successfully', severity: 'success' });
      setDeleteDialogOpen(false);
    } catch (err) {
      console.error('Error deleting contact:', err);
      const errorMessage = err.response?.data?.message || err.message || 'Failed to delete contact';
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const openEditDialog = (contact) => {
    setCurrentContact(contact);
    setFormData({
      userId: contact.userId,
      name: contact.name || '',
      category: contact.category || 'general',
      email: contact.email || '',
      phone: contact.phone || '',
      address: contact.address || '',
      notes: contact.notes || ''
    });
    setFormErrors({});
    setEditDialogOpen(true);
  };

  const openDeleteDialog = (contact) => {
    setCurrentContact(contact);
    setDeleteDialogOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, sortable: true },
    { field: 'category', headerName: 'Category', width: 120, sortable: true },
    { field: 'email', headerName: 'Email', width: 200, sortable: true },
    { field: 'phone', headerName: 'Phone', width: 150, sortable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon sx={{ color: 'var(--color-icon)' }} />}
          label="Edit"
          onClick={() => openEditDialog(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon sx={{ color: 'var(--color-icon)' }} />}
          label="Delete"
          onClick={() => openDeleteDialog(params.row)}
        />,
      ],
    },
  ];

  const renderContactForm = (isEdit = false) => (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          id={isEdit ? 'edit-name' : 'create-name'}
          name="name"
          label="Name"
          value={formData.name}
          onChange={handleInputChange}
          error={Boolean(formErrors.name)}
          helperText={formErrors.name || ' '}
          sx={{
            '& .MuiInputBase-root': { bgcolor: 'var(--color-surface)', color: 'var(--color-text)' },
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'var(--color-border)' },
            '& .MuiInputLabel-root': { color: 'var(--color-text-secondary)' }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          freeSolo
          id={isEdit ? 'edit-category' : 'create-category'}
          options={availableCategories}
          value={formData.category}
          onChange={handleCategoryChange}
          inputValue={formData.category}
          onInputChange={(event, newInputValue) => {
            setFormData({ ...formData, category: newInputValue });
            if (formErrors.category) {
              setFormErrors({ ...formErrors, category: '' });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Category"
              error={Boolean(formErrors.category)}
              helperText={formErrors.category || ' '}
              sx={{
                '& .MuiInputBase-root': { bgcolor: 'var(--color-surface)', color: 'var(--color-text)', paddingRight: '39px !important' },
                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'var(--color-border)' },
                '& .MuiInputLabel-root': { color: 'var(--color-text-secondary)' },
                '& .MuiAutocomplete-clearIndicator': { color: 'var(--color-icon)' },
                '& .MuiAutocomplete-popupIndicator': { color: 'var(--color-icon)' }
              }}
            />
          )}
          PaperComponent={(props) => (
            <Paper {...props} sx={{ bgcolor: 'var(--color-surface)', color: 'var(--color-text)' }} />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id={isEdit ? 'edit-email' : 'create-email'}
          name="email"
          label="Email"
          type="email"
          value={formData.email}
          onChange={handleInputChange}
          error={Boolean(formErrors.email)}
          helperText={formErrors.email || ' '}
          sx={{
            '& .MuiInputBase-root': { bgcolor: 'var(--color-surface)', color: 'var(--color-text)' },
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'var(--color-border)' },
            '& .MuiInputLabel-root': { color: 'var(--color-text-secondary)' }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id={isEdit ? 'edit-phone' : 'create-phone'}
          name="phone"
          label="Phone"
          type="tel"
          value={formData.phone}
          onChange={handleInputChange}
          error={Boolean(formErrors.phone)}
          helperText={formErrors.phone || ' '}
          sx={{
            '& .MuiInputBase-root': { bgcolor: 'var(--color-surface)', color: 'var(--color-text)' },
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'var(--color-border)' },
            '& .MuiInputLabel-root': { color: 'var(--color-text-secondary)' }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id={isEdit ? 'edit-address' : 'create-address'}
          name="address"
          label="Address"
          value={formData.address}
          onChange={handleInputChange}
          multiline
          rows={2}
          helperText=" "
          sx={{
            '& .MuiInputBase-root': { bgcolor: 'var(--color-surface)', color: 'var(--color-text)' },
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'var(--color-border)' },
            '& .MuiInputLabel-root': { color: 'var(--color-text-secondary)' }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id={isEdit ? 'edit-notes' : 'create-notes'}
          name="notes"
          label="Notes"
          value={formData.notes}
          onChange={handleInputChange}
          multiline
          rows={3}
          helperText=" "
          sx={{
            '& .MuiInputBase-root': { bgcolor: 'var(--color-surface)', color: 'var(--color-text)' },
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'var(--color-border)' },
            '& .MuiInputLabel-root': { color: 'var(--color-text-secondary)' }
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ width: '100%', p: 3, color: 'var(--color-text)' }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'var(--color-text)' }}>
        Contacts
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box/> {/* Spacer */}
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            resetForm();
            setCreateDialogOpen(true);
          }}
        >
          Add Contact
        </Button>
      </Box>

      <Paper sx={{ height: 'calc(100vh - 250px)', width: '100%', bgcolor: 'var(--color-surface)' }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ m: 2 }}>
            {error}
            <Button color="inherit" size="small" onClick={() => window.location.reload()}>Retry</Button>
          </Alert>
        ) : (
          <DataGrid
            rows={contacts || []}
            columns={columns}
            getRowId={(row) => row.id}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
            }}
            pageSizeOptions={[5, 10, 25, 50]}
            autoHeight={false}
            sx={{
              '--DataGrid-containerBackground': 'var(--color-background)',
              border: 'none',
              '& .MuiDataGrid-cell': { color: 'var(--color-text)', borderColor: 'var(--color-divider)' },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'var(--color-background)',
                color: 'var(--color-text)',
                borderBottom: '1px solid var(--color-border)'
              },
              '& .MuiDataGrid-footerContainer': { backgroundColor: 'var(--color-background)', color: 'var(--color-text)', borderTop: '1px solid var(--color-border)' },
              '& .MuiTablePagination-root': { color: 'var(--color-text)' },
              '& .MuiDataGrid-virtualScroller': { backgroundColor: 'var(--color-surface)' },
              '& .MuiButtonBase-root': { color: 'var(--color-text)' },
              '& .MuiSvgIcon-root': { color: 'var(--color-icon)' },
              '& .MuiDataGrid-row': { '&:hover': { backgroundColor: 'var(--color-hover)' } },
              '& .MuiDataGrid-selectedRowCount': { color: 'var(--color-text)' },
              '& .MuiTablePagination-actions .MuiIconButton-root': { color: 'var(--color-icon)' },
            }}
          />
        )}
      </Paper>

      {/* Create Contact Dialog */}
      <Dialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{ sx: { bgcolor: 'var(--color-background)', color: 'var(--color-text)' } }}
      >
        <DialogTitle>Create New Contact</DialogTitle>
        <DialogContent>
          {renderContactForm(false)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateDialogOpen(false)} sx={{ color: 'var(--color-text)' }}>Cancel</Button>
          <Button onClick={handleCreateContact} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Contact Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{ sx: { bgcolor: 'var(--color-background)', color: 'var(--color-text)' } }}
      >
        <DialogTitle>Edit Contact</DialogTitle>
        <DialogContent>
          {renderContactForm(true)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} sx={{ color: 'var(--color-text)' }}>Cancel</Button>
          <Button onClick={handleUpdateContact} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Contact Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{ sx: { bgcolor: 'var(--color-background)', color: 'var(--color-text)' } }}
      >
        <DialogTitle>Delete Contact</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'var(--color-text-secondary)' }}>
            Are you sure you want to delete the contact "{currentContact?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} sx={{ color: 'var(--color-text)' }}>Cancel</Button>
          <Button onClick={handleDeleteContact} color="error" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Contacts;
