import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../contexts/DataContext';
import { ThemeContext } from '../../contexts/ThemeContext';
import { AuthContext } from '../../contexts/AuthContext';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
  Paper,
  Grid,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { createCurrency, updateCurrency, deleteCurrency, refreshAccessToken } from '../../utils/api';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function Currencies() {
  const { currencies, fetchCurrenciesData } = useContext(DataContext);
  const { theme } = useContext(ThemeContext);
  const { accessToken, setAccessToken, logout, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Dialog states
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState(null);

  // Form state
  const [formData, setFormData] = useState({
    userId: '',
    code: '',
    name: '',
    symbol: '',
  });

  // Validation errors
  const [formErrors, setFormErrors] = useState({});

  // Handle token refresh
  const handleTokenRefresh = async () => {
    try {
      const data = await refreshAccessToken();
      if (data && data.accessToken) {
        setAccessToken(data.accessToken);
        return data.accessToken;
      }
      throw new Error('No token received');
    } catch (err) {
      console.error('Token refresh failed:', err);
      logout(); // Force logout on failed refresh
      return null;
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        setError(null);
        await fetchCurrenciesData();
      } catch (err) {
        console.error('Error loading data:', err);
        if (err.message && err.message.includes('401')) {
          try {
            const newToken = await handleTokenRefresh();
            if (newToken) {
              await fetchCurrenciesData(); // Retry with new token
            } else {
              setError('Session expired. Please log in again.');
            }
          } catch (refreshErr) {
            setError('Authentication failed. Please log in again.');
          }
        } else {
          setError('Failed to load currencies. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [fetchCurrenciesData]); // Only refetch when fetchCurrenciesData changes

  const validateForm = () => {
    const errors = {};
    if (!formData.code) errors.code = 'Code is required';
    else if (formData.code.length !== 3) errors.code = 'Code must be 3 characters';
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.symbol) errors.symbol = 'Symbol is required';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };

  const resetForm = () => {
    setFormData({
      userId: user?.id || '', // Pre-fill userId if available
      code: '',
      name: '',
      symbol: '',
    });
    setFormErrors({});
    setCurrentCurrency(null);
  };

  const handleCreateCurrency = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      const currencyData = { ...formData, userId: user.id }; // Ensure userId is included
      await createCurrency(accessToken, currencyData);
      await fetchCurrenciesData(); // Refresh list
      setSnackbar({ open: true, message: 'Currency created successfully', severity: 'success' });
      setCreateDialogOpen(false);
      resetForm();
    } catch (err) {
      console.error('Error creating currency:', err);
      setSnackbar({ open: true, message: err.message || 'Failed to create currency', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCurrency = async () => {
    if (!validateForm() || !currentCurrency) return;

    try {
      setLoading(true);
      const currencyData = { ...formData, userId: user.id }; // Ensure userId
      await updateCurrency(accessToken, currentCurrency.id, currencyData);
      await fetchCurrenciesData(); // Refresh list
      setSnackbar({ open: true, message: 'Currency updated successfully', severity: 'success' });
      setEditDialogOpen(false);
      resetForm();
    } catch (err) {
      console.error('Error updating currency:', err);
      setSnackbar({ open: true, message: err.message || 'Failed to update currency', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCurrency = async () => {
    if (!currentCurrency) return;

    try {
      setLoading(true);
      await deleteCurrency(accessToken, currentCurrency.id);
      await fetchCurrenciesData(); // Refresh list
      setSnackbar({ open: true, message: 'Currency deleted successfully', severity: 'success' });
      setDeleteDialogOpen(false);
    } catch (err) {
      console.error('Error deleting currency:', err);
      setSnackbar({ open: true, message: err.message || 'Failed to delete currency', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const openEditDialog = (currency) => {
    setCurrentCurrency(currency);
    setFormData({
      userId: currency.userId,
      code: currency.code || '',
      name: currency.name || '',
      symbol: currency.symbol || '',
    });
    setEditDialogOpen(true);
  };

  const openDeleteDialog = (currency) => {
    setCurrentCurrency(currency);
    setDeleteDialogOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const columns = [
    { field: 'code', headerName: 'Code', width: 100, sortable: true },
    { field: 'name', headerName: 'Name', flex: 1, sortable: true },
    { field: 'symbol', headerName: 'Symbol', width: 100, sortable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon sx={{ color: 'var(--color-icon)' }} />}
          label="Edit"
          onClick={() => openEditDialog(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon sx={{ color: 'var(--color-icon)' }} />}
          label="Delete"
          onClick={() => openDeleteDialog(params.row)}
        />,
      ],
    },
  ];

  const renderCurrencyForm = (isEdit = false) => (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          id={isEdit ? 'edit-code' : 'create-code'}
          name="code"
          label="Code (3 chars)"
          value={formData.code}
          onChange={handleInputChange}
          error={Boolean(formErrors.code)}
          helperText={formErrors.code}
          inputProps={{ maxLength: 3 }}
          sx={{
            '& .MuiInputBase-root': { bgcolor: 'var(--color-surface)', color: 'var(--color-text)' },
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'var(--color-border)' },
            '& .MuiInputLabel-root': { color: 'var(--color-text-secondary)' }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          id={isEdit ? 'edit-symbol' : 'create-symbol'}
          name="symbol"
          label="Symbol"
          value={formData.symbol}
          onChange={handleInputChange}
          error={Boolean(formErrors.symbol)}
          helperText={formErrors.symbol}
          sx={{
            '& .MuiInputBase-root': { bgcolor: 'var(--color-surface)', color: 'var(--color-text)' },
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'var(--color-border)' },
            '& .MuiInputLabel-root': { color: 'var(--color-text-secondary)' }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id={isEdit ? 'edit-name' : 'create-name'}
          name="name"
          label="Name"
          value={formData.name}
          onChange={handleInputChange}
          error={Boolean(formErrors.name)}
          helperText={formErrors.name}
          sx={{
            '& .MuiInputBase-root': { bgcolor: 'var(--color-surface)', color: 'var(--color-text)' },
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'var(--color-border)' },
            '& .MuiInputLabel-root': { color: 'var(--color-text-secondary)' }
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ width: '100%', p: 3, color: 'var(--color-text)' }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'var(--color-text)' }}>
        Currencies
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box/> {/* Spacer */}
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            resetForm();
            setCreateDialogOpen(true);
          }}
        >
          Add Currency
        </Button>
      </Box>

      <Paper sx={{ height: 'calc(100vh - 250px)', width: '100%', bgcolor: 'var(--color-surface)' }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ m: 2 }}>
            {error}
            <Button color="inherit" size="small" onClick={() => window.location.reload()}>Retry</Button>
          </Alert>
        ) : (
          <DataGrid
            rows={currencies || []} // Ensure rows is always an array
            columns={columns}
            getRowId={(row) => row.id}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              sorting: { sortModel: [{ field: 'code', sort: 'asc' }] },
            }}
            pageSizeOptions={[5, 10, 25, 50]}
            autoHeight={false} // Let the grid fill the Paper height
            sx={{
              '--DataGrid-containerBackground': 'var(--color-background)',
              border: 'none',
              '& .MuiDataGrid-cell': { color: 'var(--color-text)', borderColor: 'var(--color-divider)' },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'var(--color-background)',
                color: 'var(--color-text)',
                borderBottom: '1px solid var(--color-border)'
              },
              '& .MuiDataGrid-footerContainer': { backgroundColor: 'var(--color-background)', color: 'var(--color-text)', borderTop: '1px solid var(--color-border)' },
              '& .MuiTablePagination-root': { color: 'var(--color-text)' },
              '& .MuiDataGrid-virtualScroller': { backgroundColor: 'var(--color-surface)' },
              '& .MuiButtonBase-root': { color: 'var(--color-text)' },
              '& .MuiSvgIcon-root': { color: 'var(--color-icon)' },
              '& .MuiDataGrid-row': { '&:hover': { backgroundColor: 'var(--color-hover)' } },
              '& .MuiDataGrid-selectedRowCount': { color: 'var(--color-text)' },
              '& .MuiTablePagination-actions .MuiIconButton-root': { color: 'var(--color-icon)' },
            }}
          />
        )}
      </Paper>

      {/* Create Currency Dialog */}
      <Dialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{ sx: { bgcolor: 'var(--color-background)', color: 'var(--color-text)' } }}
      >
        <DialogTitle>Create New Currency</DialogTitle>
        <DialogContent>
          {renderCurrencyForm(false)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateDialogOpen(false)} sx={{ color: 'var(--color-text)' }}>Cancel</Button>
          <Button onClick={handleCreateCurrency} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Currency Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{ sx: { bgcolor: 'var(--color-background)', color: 'var(--color-text)' } }}
      >
        <DialogTitle>Edit Currency</DialogTitle>
        <DialogContent>
          {renderCurrencyForm(true)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} sx={{ color: 'var(--color-text)' }}>Cancel</Button>
          <Button onClick={handleUpdateCurrency} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Currency Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{ sx: { bgcolor: 'var(--color-background)', color: 'var(--color-text)' } }}
      >
        <DialogTitle>Delete Currency</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'var(--color-text-secondary)' }}>
            Are you sure you want to delete the currency "{currentCurrency?.code}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} sx={{ color: 'var(--color-text)' }}>Cancel</Button>
          <Button onClick={handleDeleteCurrency} color="error" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Currencies;
