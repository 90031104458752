import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../contexts/DataContext';
import { ThemeContext } from '../../contexts/ThemeContext';
import { AuthContext } from '../../contexts/AuthContext';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  Paper,
  Grid,
  Chip,
  FormHelperText,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { createBudget, updateBudget, deleteBudget, refreshAccessToken } from '../../utils/api';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { format, isValid, parseISO } from 'date-fns';

function a11yProps(index) {
  return {
    id: `budget-tab-${index}`,
    'aria-controls': `budget-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`budget-tabpanel-${index}`}
      aria-labelledby={`budget-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function Budgets() {
  const {
    budgets,
    currencies,
    accounts,
    ifrsClassifications,
    fetchBudgetsData,
    fetchCurrenciesData,
    fetchAccountsData,
    fetchIFRSClassificationsData
  } = useContext(DataContext);
  const { theme } = useContext(ThemeContext);
  const { accessToken, setAccessToken, logout, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [tabValue, setTabValue] = useState(0);

  // Dialog states
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentBudget, setCurrentBudget] = useState(null);

  // Form state
  const [formData, setFormData] = useState({
    userId: '',
    ifrsClassificationId: '',
    accountId: '',
    periodStart: format(new Date(), 'yyyy-MM-dd'),
    periodEnd: format(new Date(), 'yyyy-MM-dd'),
    amount: 0,
  });

  // Validation errors
  const [formErrors, setFormErrors] = useState({});

  // Enhanced useEffect to extract relevant data from API response and prepare for display
  const [enhancedBudgets, setEnhancedBudgets] = useState([]);

  // Handle token refresh
  const handleTokenRefresh = async () => {
    try {
      const data = await refreshAccessToken();
      if (data && data.accessToken) {
        setAccessToken(data.accessToken);
        return data.accessToken;
      }
      throw new Error('No token received');
    } catch (err) {
      console.error('Token refresh failed:', err);
      logout(); // Force logout on failed refresh
      return null;
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        setError(null);
        // Fetch all required data
        await Promise.all([
          fetchBudgetsData(),
          fetchCurrenciesData(),
          fetchAccountsData(),
          fetchIFRSClassificationsData()
        ]);
      } catch (err) {
        console.error('Error loading data:', err);
        // Try to refresh token if unauthorized
        if (err.message && err.message.includes('401')) {
          try {
            const newToken = await handleTokenRefresh();
            if (newToken) {
              // Retry with new token
              await Promise.all([
                fetchBudgetsData(),
                fetchCurrenciesData(),
                fetchAccountsData(),
                fetchIFRSClassificationsData()
              ]);
            } else {
              setError('Session expired. Please log in again.');
            }
          } catch (refreshErr) {
            setError('Authentication failed. Please log in again.');
          }
        } else {
          setError('Failed to load data. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [fetchBudgetsData, fetchCurrenciesData, fetchAccountsData, fetchIFRSClassificationsData]);

  useEffect(() => {
    if (!budgets || !currencies || !accounts || !ifrsClassifications) {
      setEnhancedBudgets([]);
      return;
    }

    try {
      // Debug raw data
      console.log('Processing budgets data...');
      console.log('Raw budgets:', JSON.stringify(budgets, null, 2));

      // Create flattened budget objects with directly accessible fields
      const flattenedBudgets = budgets.map(budget => {
        try {
          // Debug individual budget
          console.log(`Processing budget ID=${budget.id}`);
          console.log(` - Raw amount: ${budget.amount}, type: ${typeof budget.amount}`);

          // Find the related objects
          const account = budget.account || accounts.find(a => a.id === budget.accountId);
          const ifrsClass = budget.ifrsClassification || ifrsClassifications.find(c => c.id === budget.ifrsClassificationId);

          // Get currency information directly from the nested account.currency object
          let currencySymbol = '$';
          let currencyCode = 'USD';

          // First try to get currency from the nested account data in the API response
          if (account && account.currency) {
            currencySymbol = account.currency.symbol || '$';
            currencyCode = account.currency.code || 'USD';
            console.log(` - Currency found from account.currency: ${currencyCode} (${currencySymbol})`);
          }
          // Fallback to looking up by currencyId if nested data isn't available
          else if (account && account.currencyId) {
            const accountCurrency = currencies.find(c => c.id === account.currencyId);
            if (accountCurrency) {
              currencySymbol = accountCurrency.symbol || '$';
              currencyCode = accountCurrency.code || 'USD';
              console.log(` - Currency found from currencies array: ${currencyCode} (${currencySymbol})`);
            }
          }

          // SIMPLIFIED: Just keep the original amount value, don't try to transform it
          // This preserves whatever format it came in from the API
          console.log(` - Using original amount value: ${budget.amount}`);

          // Create the enhanced budget object
          const enhancedBudget = {
            ...budget,
            periodStartFormatted: budget.periodStart ? format(parseISO(budget.periodStart), 'yyyy-MM-dd') : 'N/A',
            periodEndFormatted: budget.periodEnd ? format(parseISO(budget.periodEnd), 'yyyy-MM-dd') : 'N/A',
            accountName: account ? account.name : 'N/A',
            ifrsName: ifrsClass ? ifrsClass.name : 'N/A',
            currencySymbol,
            currencyCode,
            // Simply preserve the original amount
            amount: budget.amount
          };

          console.log(` - Enhanced budget:`, enhancedBudget);
          return enhancedBudget;
        } catch (err) {
          console.error('Error processing budget:', err, budget);
          // Return a default object if there's an error with this budget
          return {
            ...budget,
            periodStartFormatted: budget.periodStart ? format(parseISO(budget.periodStart), 'yyyy-MM-dd') : 'N/A',
            periodEndFormatted: budget.periodEnd ? format(parseISO(budget.periodEnd), 'yyyy-MM-dd') : 'N/A',
            accountName: 'N/A',
            ifrsName: 'N/A',
            currencySymbol: '$',
            currencyCode: 'USD',
            amount: budget.amount || 0
          };
        }
      });

      console.log('Processed budgets:', flattenedBudgets);
      setEnhancedBudgets(flattenedBudgets);
    } catch (err) {
      console.error('Error in budgets data mapping:', err);
      setEnhancedBudgets([]);
    }
  }, [budgets, currencies, accounts, ifrsClassifications]);

  // Add this at the top level of the component to see exactly what we're getting from the API
  useEffect(() => {
    if (budgets && budgets.length > 0) {
      console.log('BUDGET DATA CHECK:');
      console.log('First budget object:', budgets[0]);
      console.log('Amount type:', typeof budgets[0].amount);
      console.log('Amount value:', budgets[0].amount);
      console.log('Amount toString():', budgets[0].amount ? budgets[0].amount.toString() : 'null');
      console.log('Raw budgets array:', budgets);
    }
  }, [budgets]);

  const handleCreateBudget = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);

      // Get userId from context, with fallback to localStorage
      const userId = user?.id || localStorage.getItem('userId');

      if (!userId) {
        throw new Error('User ID not found. Please log in again.');
      }

      // Add the current user's ID to the form data
      const budgetData = {
        ...formData,
        userId: userId
      };

      await createBudget(accessToken, budgetData);
      await fetchBudgetsData(); // Refresh budgets list
      setSnackbar({ open: true, message: 'Budget created successfully', severity: 'success' });
      setCreateDialogOpen(false);
      resetForm();
    } catch (err) {
      console.error('Error creating budget:', err);
      setSnackbar({ open: true, message: err.message || 'Failed to create budget', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateBudget = async () => {
    if (!validateForm() || !currentBudget) return;

    try {
      setLoading(true);

      // Get userId from context, with fallback to localStorage
      const userId = user?.id || localStorage.getItem('userId');

      if (!userId) {
        throw new Error('User ID not found. Please log in again.');
      }

      // Ensure userId is included in update data
      const budgetData = {
        ...formData,
        userId: userId
      };

      await updateBudget(accessToken, currentBudget.id, budgetData);
      await fetchBudgetsData(); // Refresh budgets list
      setSnackbar({ open: true, message: 'Budget updated successfully', severity: 'success' });
      setEditDialogOpen(false);
      resetForm();
    } catch (err) {
      console.error('Error updating budget:', err);
      setSnackbar({ open: true, message: err.message || 'Failed to update budget', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteBudget = async () => {
    if (!currentBudget) return;

    try {
      setLoading(true);
      await deleteBudget(accessToken, currentBudget.id);
      await fetchBudgetsData(); // Refresh budgets list
      setSnackbar({ open: true, message: 'Budget deleted successfully', severity: 'success' });
      setDeleteDialogOpen(false);
    } catch (err) {
      console.error('Error deleting budget:', err);
      setSnackbar({ open: true, message: err.message || 'Failed to delete budget', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const openEditDialog = (budget) => {
    setCurrentBudget(budget);
    setFormData({
      ifrsClassificationId: budget.ifrsClassificationId || '',
      accountId: budget.accountId || '',
      periodStart: budget.periodStart || format(new Date(), 'yyyy-MM-dd'),
      periodEnd: budget.periodEnd || format(new Date(), 'yyyy-MM-dd'),
      amount: budget.amount || 0,
    });
    setEditDialogOpen(true);
  };

  const openDeleteDialog = (budget) => {
    setCurrentBudget(budget);
    setDeleteDialogOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear validation error when field is filled
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: '',
      });
    }
  };

  const resetForm = () => {
    setFormData({
      ifrsClassificationId: '',
      accountId: '',
      periodStart: format(new Date(), 'yyyy-MM-dd'),
      periodEnd: format(new Date(), 'yyyy-MM-dd'),
      amount: 0,
    });
    setFormErrors({});
    setCurrentBudget(null);
  };

  const validateForm = () => {
    const errors = {};

    // Validate period dates
    if (!formData.periodStart) {
      errors.periodStart = 'Start date is required';
    }

    if (!formData.periodEnd) {
      errors.periodEnd = 'End date is required';
    }

    // Compare dates to ensure end is after start
    if (formData.periodStart && formData.periodEnd) {
      const startDate = new Date(formData.periodStart);
      const endDate = new Date(formData.periodEnd);
      if (endDate < startDate) {
        errors.periodEnd = 'End date must be after start date';
      }
    }

    // Validate amount is a positive number
    if (!formData.amount || formData.amount <= 0) {
      errors.amount = 'Amount must be a positive number';
    }

    // At least one of IFRS or account must be selected
    if (!formData.ifrsClassificationId && !formData.accountId) {
      errors.ifrsClassificationId = 'Either IFRS classification or account must be selected';
      errors.accountId = 'Either IFRS classification or account must be selected';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Configure columns for the DataGrid
  const columns = [
    {
      field: 'periodStartFormatted',
      headerName: 'Start Date',
      flex: 1,
      sortable: true,
    },
    {
      field: 'periodEndFormatted',
      headerName: 'End Date',
      flex: 1,
      sortable: true,
    },
    {
      field: 'accountName',
      headerName: 'Account',
      flex: 1,
      sortable: true,
    },
    {
      field: 'currencyCode',
      headerName: 'Currency',
      width: 100,
      sortable: true,
      renderCell: (params) => {
        // Direct debug output to see what we have
        console.log('Currency cell:', params.row);

        // Just get the currency code directly, or use USD as fallback
        return params.row?.currencyCode || 'USD';
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 150,
      sortable: true,
      renderCell: (params) => {
        // Check if we have a row object
        if (!params.row) return '-';

        // Get the raw amount directly from the row
        let amountValue = null;

        // First try to access the amount directly from the enhancedBudget object
        if (params.row.amount !== undefined && params.row.amount !== null) {
          amountValue = params.row.amount;
        }
        // If there's no amount, try to access the original budget object
        else if (params.row.budget && params.row.budget.amount !== undefined) {
          amountValue = params.row.budget.amount;
        }

        // If we couldn't find an amount value, show a dash
        if (amountValue === null || amountValue === undefined) {
          return '-';
        }

        // Format the amount directly - ensure it's treated as a number
        try {
          // Convert to string first in case it's a Decimal object
          const amountStr = amountValue.toString();
          // Then parse as float to get a regular number
          const amountNum = parseFloat(amountStr);

          // Format with currency symbol and 2 decimal places
          if (isNaN(amountNum)) {
            return '-';
          }

          return `$${amountNum.toFixed(2)}`;
        } catch (error) {
          console.error('Error formatting amount:', error);
          // If all else fails, just display the raw amount
          return amountValue;
        }
      },
    },
    {
      field: 'ifrsName',
      headerName: 'IFRS Classification',
      flex: 1,
      sortable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon sx={{ color: 'var(--color-icon)' }} />}
          label="Edit"
          onClick={() => openEditDialog(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon sx={{ color: 'var(--color-icon)' }} />}
          label="Delete"
          onClick={() => openDeleteDialog(params.row)}
        />,
      ],
    },
  ];

  const renderBudgetForm = () => {
    // Get selected account details
    const selectedAccount = accounts?.find(a => a.id === formData.accountId) || null;
    const accountCurrency = selectedAccount ?
      currencies?.find(c => c.id === selectedAccount.currencyId) : null;

    return (
    <Grid container spacing={3} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Create a New Budget
        </Typography>
      </Grid>

      {/* IFRS Classification */}
      <Grid item xs={12} md={6}>
        <FormControl fullWidth error={Boolean(formErrors.ifrsClassificationId)}>
          <InputLabel id="ifrs-classification-label">IFRS Classification</InputLabel>
          <Select
            labelId="ifrs-classification-label"
            id="ifrsClassificationId"
            name="ifrsClassificationId"
            value={formData.ifrsClassificationId}
            onChange={handleInputChange}
            label="IFRS Classification"
            sx={{
              bgcolor: 'var(--color-surface)',
              color: 'var(--color-text)',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--color-border)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--color-border-hover)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--color-primary)',
              }
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: 'var(--color-surface)',
                  '& .MuiMenuItem-root': {
                    color: 'var(--color-text)',
                  },
                  '& .MuiMenuItem-root:hover': {
                    backgroundColor: 'var(--color-hover)',
                  },
                },
              },
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {ifrsClassifications && ifrsClassifications.map((classification) => (
              <MenuItem key={classification.id} value={classification.id}>
                {classification.name}
              </MenuItem>
            ))}
          </Select>
          {formErrors.ifrsClassificationId && (
            <FormHelperText>{formErrors.ifrsClassificationId}</FormHelperText>
          )}
        </FormControl>
      </Grid>

      {/* Account */}
      <Grid item xs={12} md={6}>
        <FormControl fullWidth error={Boolean(formErrors.accountId)}>
          <InputLabel id="account-label">Account</InputLabel>
          <Select
            labelId="account-label"
            id="accountId"
            name="accountId"
            value={formData.accountId}
            onChange={handleInputChange}
            label="Account"
            sx={{
              bgcolor: 'var(--color-surface)',
              color: 'var(--color-text)',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--color-border)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--color-border-hover)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--color-primary)',
              }
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: 'var(--color-surface)',
                  '& .MuiMenuItem-root': {
                    color: 'var(--color-text)',
                  },
                  '& .MuiMenuItem-root:hover': {
                    backgroundColor: 'var(--color-hover)',
                  },
                },
              },
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {accounts && accounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>
                {account.name} {account.currencyId && currencies ?
                  `(${currencies.find(c => c.id === account.currencyId)?.code || ''})` : ''}
              </MenuItem>
            ))}
          </Select>
          {formErrors.accountId && (
            <FormHelperText>{formErrors.accountId}</FormHelperText>
          )}
          {accountCurrency && (
            <FormHelperText>
              Currency: {accountCurrency.code} ({accountCurrency.symbol})
            </FormHelperText>
          )}
        </FormControl>
      </Grid>

      {/* Period Start */}
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          id="periodStart"
          name="periodStart"
          label="Period Start"
          type="date"
          value={formData.periodStart}
          onChange={handleInputChange}
          error={Boolean(formErrors.periodStart)}
          helperText={formErrors.periodStart}
          InputLabelProps={{ shrink: true }}
          sx={{
            '& .MuiInputBase-root': {
              bgcolor: 'var(--color-surface)',
              color: 'var(--color-text)',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--color-border)',
            },
            '& .MuiInputLabel-root': {
              color: 'var(--color-text-secondary)',
            },
            '& .MuiSvgIcon-root': {
              color: 'var(--color-icon)',
            },
            '& input::-webkit-calendar-picker-indicator': {
              filter: theme === 'dark' ? 'invert(1)' : 'none',
            },
          }}
        />
      </Grid>

      {/* Period End */}
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          id="periodEnd"
          name="periodEnd"
          label="Period End"
          type="date"
          value={formData.periodEnd}
          onChange={handleInputChange}
          error={Boolean(formErrors.periodEnd)}
          helperText={formErrors.periodEnd}
          InputLabelProps={{ shrink: true }}
          sx={{
            '& .MuiInputBase-root': {
              bgcolor: 'var(--color-surface)',
              color: 'var(--color-text)',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--color-border)',
            },
            '& .MuiInputLabel-root': {
              color: 'var(--color-text-secondary)',
            },
            '& .MuiSvgIcon-root': {
              color: 'var(--color-icon)',
            },
            '& input::-webkit-calendar-picker-indicator': {
              filter: theme === 'dark' ? 'invert(1)' : 'none',
            },
          }}
        />
      </Grid>

      {/* Amount */}
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="amount"
          name="amount"
          label={`Amount${accountCurrency ? ` (${accountCurrency.code})` : ''}`}
          type="number"
          inputProps={{
            step: "0.01",
            min: "0"
          }}
          value={formData.amount}
          onChange={handleInputChange}
          error={Boolean(formErrors.amount)}
          helperText={formErrors.amount || 'Enter value with up to 2 decimal places'}
          sx={{
            '& .MuiInputBase-root': {
              bgcolor: 'var(--color-surface)',
              color: 'var(--color-text)',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--color-border)',
            },
            '& .MuiInputLabel-root': {
              color: 'var(--color-text-secondary)',
            },
            '& .MuiSvgIcon-root': {
              color: 'var(--color-icon)',
            },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateBudget}
          disabled={loading}
          sx={{ mt: 2 }}
        >
          {loading ? <CircularProgress size={24} /> : 'Create Budget'}
        </Button>
      </Grid>
    </Grid>
  )};

  const renderBudgetsList = () => (
    <Box sx={{ height: 500, width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Budgets
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            resetForm();
            setCreateDialogOpen(true);
          }}
        >
          Add Budget
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
          <Button color="inherit" size="small" onClick={() => window.location.reload()}>
            Retry
          </Button>
        </Alert>
      ) : (
        <DataGrid
          rows={enhancedBudgets}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            sorting: {
              sortModel: [{ field: 'periodStartFormatted', sort: 'desc' }],
            },
          }}
          pageSizeOptions={[5, 10, 25, 50]}
          getRowId={(row) => row.id}
          autoHeight
          sx={{
            '& .MuiDataGrid-cell': {
              color: 'var(--color-text)',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'var(--color-background)',
              color: 'var(--color-text)',
              borderBottom: '1px solid var(--color-border)',
            },
            '& .MuiDataGrid-footerContainer': {
              backgroundColor: 'var(--color-background)',
              color: 'var(--color-text)',
              borderTop: '1px solid var(--color-border)',
            },
            '& .MuiTablePagination-root': {
              color: 'var(--color-text)',
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: 'var(--color-surface)',
            },
            '& .MuiButtonBase-root': {
              color: 'var(--color-text)',
            },
            '& .MuiDataGrid-iconButtonContainer, & .MuiButtonBase-root.MuiIconButton-root': {
              color: 'var(--color-text)',
            },
            '& .MuiDataGrid-menuIcon': {
              color: 'var(--color-icon)',
            },
            '& .MuiSvgIcon-root': {
              color: 'var(--color-icon)',
            },
            '& .MuiDataGrid-row': {
              borderBottom: '1px solid var(--color-border)',
            },
            '& .MuiDataGrid-columnSeparator': {
              color: 'var(--color-border)',
            },
            '& .MuiDataGrid-selectedRowCount': {
              color: 'var(--color-text)',
            },
            '& svg': {
              fill: 'var(--color-icon)',
            },
            '& .MuiTablePagination-actions .MuiIconButton-root': {
              color: 'var(--color-icon)',
            },
            '& .MuiInputBase-root': {
              color: 'var(--color-text)',
            },
            border: 'none',
          }}
        />
      )}
    </Box>
  );

  return (
    <Box sx={{ width: '100%', p: 3, color: 'var(--color-text)' }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="budget tabs"
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'var(--color-primary)',
          },
          '& .MuiTab-root': {
            color: 'var(--color-text-secondary)',
            '&.Mui-selected': {
              color: 'var(--color-primary)',
            },
          },
          borderBottom: '1px solid var(--color-border)',
        }}
      >
        <Tab label="View Budgets" {...a11yProps(0)} />
        <Tab label="Add Budget" {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        {renderBudgetsList()}
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        {renderBudgetForm()}
      </TabPanel>

      {/* Edit Budget Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            bgcolor: 'var(--color-background)',
            color: 'var(--color-text)',
          }
        }}
      >
        <DialogTitle>Edit Budget</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            {/* IFRS Classification */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={Boolean(formErrors.ifrsClassificationId)}>
                <InputLabel id="edit-ifrs-classification-label" sx={{ color: 'var(--color-text-secondary)' }}>
                  IFRS Classification
                </InputLabel>
                <Select
                  labelId="edit-ifrs-classification-label"
                  id="edit-ifrsClassificationId"
                  name="ifrsClassificationId"
                  value={formData.ifrsClassificationId}
                  onChange={handleInputChange}
                  label="IFRS Classification"
                  sx={{
                    bgcolor: 'var(--color-surface)',
                    color: 'var(--color-text)',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--color-border)',
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: 'var(--color-surface)',
                        '& .MuiMenuItem-root': {
                          color: 'var(--color-text)',
                        },
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: 'var(--color-hover)',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ifrsClassifications && ifrsClassifications.map((classification) => (
                    <MenuItem key={classification.id} value={classification.id}>
                      {classification.name}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.ifrsClassificationId && (
                  <FormHelperText>{formErrors.ifrsClassificationId}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* Account */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={Boolean(formErrors.accountId)}>
                <InputLabel id="edit-account-label" sx={{ color: 'var(--color-text-secondary)' }}>
                  Account
                </InputLabel>
                <Select
                  labelId="edit-account-label"
                  id="edit-accountId"
                  name="accountId"
                  value={formData.accountId}
                  onChange={handleInputChange}
                  label="Account"
                  sx={{
                    bgcolor: 'var(--color-surface)',
                    color: 'var(--color-text)',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--color-border)',
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: 'var(--color-surface)',
                        '& .MuiMenuItem-root': {
                          color: 'var(--color-text)',
                        },
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: 'var(--color-hover)',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {accounts && accounts.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.name} {account.currencyId && currencies ?
                        `(${currencies.find(c => c.id === account.currencyId)?.code || ''})` : ''}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.accountId && (
                  <FormHelperText>{formErrors.accountId}</FormHelperText>
                )}
                {formData.accountId && (
                  <FormHelperText>
                    Currency: {accounts.find(a => a.id === formData.accountId)?.currencyId &&
                      currencies.find(c => c.id === accounts.find(a => a.id === formData.accountId)?.currencyId)?.code}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* Period Start */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="edit-periodStart"
                name="periodStart"
                label="Period Start"
                type="date"
                value={formData.periodStart}
                onChange={handleInputChange}
                error={Boolean(formErrors.periodStart)}
                helperText={formErrors.periodStart}
                InputLabelProps={{ shrink: true }}
                sx={{
                  '& .MuiInputBase-root': {
                    bgcolor: 'var(--color-surface)',
                    color: 'var(--color-text)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--color-text-secondary)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-icon)',
                  },
                  '& input::-webkit-calendar-picker-indicator': {
                    filter: theme === 'dark' ? 'invert(1)' : 'none',
                  },
                }}
              />
            </Grid>

            {/* Period End */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="edit-periodEnd"
                name="periodEnd"
                label="Period End"
                type="date"
                value={formData.periodEnd}
                onChange={handleInputChange}
                error={Boolean(formErrors.periodEnd)}
                helperText={formErrors.periodEnd}
                InputLabelProps={{ shrink: true }}
                sx={{
                  '& .MuiInputBase-root': {
                    bgcolor: 'var(--color-surface)',
                    color: 'var(--color-text)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--color-text-secondary)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-icon)',
                  },
                  '& input::-webkit-calendar-picker-indicator': {
                    filter: theme === 'dark' ? 'invert(1)' : 'none',
                  },
                }}
              />
            </Grid>

            {/* Amount */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="edit-amount"
                name="amount"
                label={`Amount${formData.accountId && accounts ?
                  ` (${currencies.find(c => c.id === accounts.find(a => a.id === formData.accountId)?.currencyId)?.code || ''})` : ''}`}
                type="number"
                inputProps={{
                  step: "0.01",
                  min: "0"
                }}
                value={formData.amount}
                onChange={handleInputChange}
                error={Boolean(formErrors.amount)}
                helperText={formErrors.amount || 'Enter value with up to 2 decimal places'}
                sx={{
                  '& .MuiInputBase-root': {
                    bgcolor: 'var(--color-surface)',
                    color: 'var(--color-text)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--color-text-secondary)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-icon)',
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} sx={{ color: 'var(--color-text)' }}>Cancel</Button>
          <Button onClick={handleUpdateBudget} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Budget Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          sx: {
            bgcolor: 'var(--color-background)',
            color: 'var(--color-text)',
          }
        }}
      >
        <DialogTitle>Delete Budget</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'var(--color-text-secondary)' }}>
            Are you sure you want to delete this budget? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} sx={{ color: 'var(--color-text)' }}>Cancel</Button>
          <Button onClick={handleDeleteBudget} color="error" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Budget Dialog */}
      <Dialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            bgcolor: 'var(--color-background)',
            color: 'var(--color-text)',
          }
        }}
      >
        <DialogTitle>Create New Budget</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            {/* IFRS Classification */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={Boolean(formErrors.ifrsClassificationId)}>
                <InputLabel id="create-ifrs-classification-label" sx={{ color: 'var(--color-text-secondary)' }}>
                  IFRS Classification
                </InputLabel>
                <Select
                  labelId="create-ifrs-classification-label"
                  id="create-ifrsClassificationId"
                  name="ifrsClassificationId"
                  value={formData.ifrsClassificationId}
                  onChange={handleInputChange}
                  label="IFRS Classification"
                  sx={{
                    bgcolor: 'var(--color-surface)',
                    color: 'var(--color-text)',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--color-border)',
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: 'var(--color-surface)',
                        '& .MuiMenuItem-root': {
                          color: 'var(--color-text)',
                        },
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: 'var(--color-hover)',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ifrsClassifications && ifrsClassifications.map((classification) => (
                    <MenuItem key={classification.id} value={classification.id}>
                      {classification.name}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.ifrsClassificationId && (
                  <FormHelperText>{formErrors.ifrsClassificationId}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* Account */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={Boolean(formErrors.accountId)}>
                <InputLabel id="create-account-label" sx={{ color: 'var(--color-text-secondary)' }}>
                  Account
                </InputLabel>
                <Select
                  labelId="create-account-label"
                  id="create-accountId"
                  name="accountId"
                  value={formData.accountId}
                  onChange={handleInputChange}
                  label="Account"
                  sx={{
                    bgcolor: 'var(--color-surface)',
                    color: 'var(--color-text)',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--color-border)',
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: 'var(--color-surface)',
                        '& .MuiMenuItem-root': {
                          color: 'var(--color-text)',
                        },
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: 'var(--color-hover)',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {accounts && accounts.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.name} {account.currencyId && currencies ?
                        `(${currencies.find(c => c.id === account.currencyId)?.code || ''})` : ''}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.accountId && (
                  <FormHelperText>{formErrors.accountId}</FormHelperText>
                )}
                {formData.accountId && (
                  <FormHelperText>
                    Currency: {accounts.find(a => a.id === formData.accountId)?.currencyId &&
                      currencies.find(c => c.id === accounts.find(a => a.id === formData.accountId)?.currencyId)?.code}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* Period Start */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="create-periodStart"
                name="periodStart"
                label="Period Start"
                type="date"
                value={formData.periodStart}
                onChange={handleInputChange}
                error={Boolean(formErrors.periodStart)}
                helperText={formErrors.periodStart}
                InputLabelProps={{ shrink: true }}
                sx={{
                  '& .MuiInputBase-root': {
                    bgcolor: 'var(--color-surface)',
                    color: 'var(--color-text)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--color-text-secondary)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-icon)',
                  },
                  '& input::-webkit-calendar-picker-indicator': {
                    filter: theme === 'dark' ? 'invert(1)' : 'none',
                  },
                }}
              />
            </Grid>

            {/* Period End */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="create-periodEnd"
                name="periodEnd"
                label="Period End"
                type="date"
                value={formData.periodEnd}
                onChange={handleInputChange}
                error={Boolean(formErrors.periodEnd)}
                helperText={formErrors.periodEnd}
                InputLabelProps={{ shrink: true }}
                sx={{
                  '& .MuiInputBase-root': {
                    bgcolor: 'var(--color-surface)',
                    color: 'var(--color-text)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--color-text-secondary)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-icon)',
                  },
                  '& input::-webkit-calendar-picker-indicator': {
                    filter: theme === 'dark' ? 'invert(1)' : 'none',
                  },
                }}
              />
            </Grid>

            {/* Amount */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="create-amount"
                name="amount"
                label={`Amount${formData.accountId && accounts ?
                  ` (${currencies.find(c => c.id === accounts.find(a => a.id === formData.accountId)?.currencyId)?.code || ''})` : ''}`}
                type="number"
                inputProps={{
                  step: "0.01",
                  min: "0"
                }}
                value={formData.amount}
                onChange={handleInputChange}
                error={Boolean(formErrors.amount)}
                helperText={formErrors.amount || 'Enter value with up to 2 decimal places'}
                sx={{
                  '& .MuiInputBase-root': {
                    bgcolor: 'var(--color-surface)',
                    color: 'var(--color-text)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-border)',
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--color-text-secondary)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'var(--color-icon)',
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateDialogOpen(false)} sx={{ color: 'var(--color-text)' }}>Cancel</Button>
          <Button onClick={handleCreateBudget} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Budgets;
