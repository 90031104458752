import React, { createContext, useContext, useState, useCallback } from 'react';
import { fetchUsers, fetchIFRSClassifications, fetchAccounts,
  fetchCurrencies, fetchTransactions, fetchTransactionLines,
  fetchContacts, fetchTags, fetchBudgets
} from '../utils/api';
import { AuthContext } from './AuthContext';

// Import API_URL from api.js
import { API_URL } from '../utils/api';

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const { accessToken } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [ifrsClassifications, setIFRSClassifications] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionLines, setTransactionLines] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [tags, setTags] = useState([]);
  const [budgets, setBudgets] = useState([]);

  // Memoize the fetchData function to prevent re-creation on every render

  // Fetch only users
  const fetchUsersData = useCallback(async () => {
    try {
      const usersData = await fetchUsers(accessToken);
      setUsers(usersData);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  }, [accessToken]);


  // Fetch only IFRS classifications
  const fetchIFRSClassificationsData = useCallback(async () => {
    try {
      const ifrsData = await fetchIFRSClassifications(accessToken);
      setIFRSClassifications(ifrsData);
    } catch (error) {
      console.error('Failed to fetch IFRS classifications:', error);
    }
  }, [accessToken]);

  // Fetch only accounts
  const fetchAccountsData = useCallback(async () => {
    try {
      const accountsData = await fetchAccounts(accessToken);
      setAccounts(accountsData);
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
    }
  }, [accessToken]);

  // Fetch only currencies
  const fetchCurrenciesData = useCallback(async () => {
    try {
      const currenciesData = await fetchCurrencies(accessToken);
      setCurrencies(currenciesData);
    } catch (error) {
      console.error('Failed to fetch currencies:', error);
    }
  }, [accessToken]);

  // Fetch only transactions
  const fetchTransactionsData = useCallback(async () => {
    try {
      // Try first to get transactions with amount
      const response = await fetch(`${API_URL}/transactions/with-amount`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        console.error('Error fetching transactions with amount:', response.status, response.statusText);

        // Fall back to regular transactions endpoint if with-amount fails
        const fallbackResponse = await fetch(`${API_URL}/transactions`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        if (!fallbackResponse.ok) {
          const errorText = await fallbackResponse.text();
          console.error('Failed to fetch transactions:', fallbackResponse.status, errorText);
          throw new Error(`Failed to fetch transactions: ${fallbackResponse.statusText}`);
        }

        const fallbackData = await fallbackResponse.json();
        setTransactions(fallbackData);
        return fallbackData;
      }

      const data = await response.json();
      setTransactions(data);
      return data;
    } catch (error) {
      console.error('Error in fetchTransactionsData:', error);
      setTransactions([]); // Set empty array on error
      throw error;
    }
  }, [accessToken]);

  // Fetch only transaction lines
  const fetchTransactionLinesData = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/transaction-lines`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Failed to fetch transaction lines:', response.status, errorText);
        throw new Error(`Failed to fetch transaction lines: ${response.statusText}`);
      }

      const data = await response.json();
      setTransactionLines(data);
      return data;
    } catch (error) {
      console.error('Failed to fetch transaction lines:', error);
      setTransactionLines([]); // Set empty array on error
    }
  }, [accessToken]);

  // Fetch only contacts
  const fetchContactsData = useCallback(async () => {
    try {
      const contactsData = await fetchContacts(accessToken);
      setContacts(contactsData);
    } catch (error) {
      console.error('Failed to fetch contacts:', error);
    }
  }, [accessToken]);

  // Fetch only tags
  const fetchTagsData = useCallback(async () => {
    try {
      const tagsData = await fetchTags(accessToken);
      setTags(tagsData);
    } catch (error) {
      console.error('Failed to fetch tags:', error);
    }
  }, [accessToken]);

  // Fetch only budgets
  const fetchBudgetsData = useCallback(async () => {
    try {
      const budgetsData = await fetchBudgets(accessToken);
      setBudgets(budgetsData);
    } catch (error) {
      console.error('Failed to fetch budgets:', error);
    }
  }, [accessToken]);

  return (
    <DataContext.Provider value={{
      users,
      ifrsClassifications,
      accounts,
      currencies,
      transactions,
      transactionLines,
      contacts,
      tags,
      budgets,
      fetchUsersData,
      fetchIFRSClassificationsData,
      fetchAccountsData,
      fetchCurrenciesData,
      fetchTransactionsData,
      fetchTransactionLinesData,
      fetchContactsData,
      fetchTagsData,
      fetchBudgetsData,
    }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
